import "./Cards.scss";
import "./card-renew.scss";
import { AiOutlineShoppingCart, AiOutlineShareAlt } from "react-icons/ai";
import { añadirCarrito } from "../redux/actions/productos";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import Swal from "sweetalert2";
function Cards({ history, product }) {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const guardarcarrito = (event) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: "success",
      title: "producto agregado al Carrito",
    });
    dispatch(añadirCarrito(product));
    event.stopPropagation();
  };

  const shareButton = (event) =>{
    if(navigator.share){
      navigator.share({
        title: product.titulo,
        text: product.textdescripsion[0],
        url: `/producto/${product.titulo.replace(/ /g, "-").toLowerCase()}`,
      })
      .then(()=>console.log("compartido"))
      .catch((error)=>{
        console.log(error);
      })
    }
    event.stopPropagation();
  }


  const redirect = () => {
    history.push(`/producto/${product.titulo.replace(/ /g, "-").toLowerCase()}`);
  };
  return (
    <>
      {/* <div className="card" onClick={redirect}>
        <div className="card-img">
          <img src={image} alt="mobile" />
        </div>
        <div className="card-body">
          <div className="left">
            <h2 className="bold">{mobile}</h2>
            <br></br>
            <span className="priceproductos">
              {"$" + new Intl.NumberFormat().format(parseInt(actual_price))}
            </span>

            <br></br>

            {domicilio === "true" ? (
              <span className="disp">Envio Gratis</span>
            ) : null}
          </div>
        </div>
      </div> */}

      <div className="d-flex flex-column card-renew mb-5" onClick={redirect}>
        <div className="d-flex flex-row card-renew-container-image">
          <img
            src={
              product.fotosdescripsion[0]?.secure_url
                ? product.fotosdescripsion[0].secure_url
                : "https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg"
            }
            className="card-renew-image"
          />
        </div>
        <div className="d-flex flex-column px-3 pb-3">
          <h3 className="card-renew-title">{product.titulo.substring(0, 18)}</h3>
          <p className="card-renew-descripcion">{product.textdescripsion[0].substring(0, 25) + " ..."}</p>
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-row justify-content-between" style={{width:"30%"}}>
              <div className="card-renew-subButton green d-flex flex-column me-4 justify-content-center" onClick={guardarcarrito}>
                <AiOutlineShoppingCart fontSize="20px" color="#EBEBEB"/>
              </div>
              <div className="card-renew-subButton green-dark d-flex flex-column justify-content-center" onClick={shareButton}>
                <AiOutlineShareAlt fontSize="20px" color="#3D9A67"/>
              </div>
            </div>
            <p className="card-renew-price">${new Intl.NumberFormat().format(
                              parseInt(product.detalles[0].Precio)
                            )}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
