import React, { useCallback, useContext, useEffect, useState } from "react";
import "./CrearProducto.scss";
import { UploadPhoto } from "../helpers/cloudinaryUpload";
import { SocketContext } from "../redux/context/contextchat";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { fetchCToken } from "../helpers/fetchmetod";
import { cargarcategorias } from "../redux/actions/productos";
import CajaCategoriaSubidas from "./CajaCategoriasSubidas";
import CircularProgress from "./CircularProgress";
import { useParams } from "react-router-dom";
import { VscDiffAdded, VscError } from "react-icons/vsc";

export const CrearCategoria = ({ history }) => {
  let { crear } = useParams();

  const [carga, setCarga] = useState(true);
  const dispatch = useDispatch();
  const productos = useSelector((productos) => productos.productos.categorias);
  const user = useSelector((user) => user.infoUsuario.rol);
  const [Categorias, setCategorias] = useState([]);
  const { socket } = useContext(SocketContext);
  const [NewProducto, setNewProducto] = useState({
    mostrar: false,
    Categoria: "",
    familia: crear,
    hijo: "",
  });

  const obtenerCategorias = useCallback(async () => {
    let categorias = [];
    const ordenes = await fetchCToken(`crearCategoria/${crear}`);

    if (crear === "familia" || crear === "categoria") {
      categorias = await fetchCToken(`categorias/familia`);
    } else if (crear === "subCategoria") {
      categorias = await fetchCToken(`categorias/categoria`);
    }

    if (!ordenes.ok || !categorias.ok) {
      return;
    }
    setCarga(false);
    setCategorias(categorias.indi);
    setNewProducto({ hijo: categorias.indi[0] });
    dispatch(cargarcategorias(ordenes.categorias));
  }, [dispatch, crear]);
  useEffect(() => {
    obtenerCategorias();
  }, [obtenerCategorias]);
  const [urlmas, setUrl] = useState({
    secure_url:
      "https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg",
    public_id: 0,
  });
  const [agregar, setagregar] = useState(false);
  const onChangeMensaje = (e) => {
    const { name, value } = e.target;
    if (name === "mostrar") {
      setNewProducto({
        ...NewProducto,
        mostrar: e.target.checked,
      });
    } else {
      setNewProducto({
        ...NewProducto,
        [name]: value,
      });
    }
  };

  const cambiar = () => {
    if (user === "Administrador" || user === "Empleado") {
      setagregar(!agregar);
      setNewProducto({
        mostrar: false,
        Categoria: "",
        familia: crear,
        hijo: Categorias[0],
      });
      setUrl({
        secure_url:
          "https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg",
        public_id: 0,
      });
    } else {
      return Swal.fire({
        position: "top-end",
        icon: "error",
        title: "No tienes Permiso",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const cambiarCategoria = (redireccion) => {
    history.push(`/crearCategorias/${redireccion}`);
  };

  const onFilesave = async (e) => {
    const file = e.target.files[0];
    setUrl(file);
  };
  const onFile = () => {
    document.querySelector("#fileproducto").click();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      NewProducto.Categoria.length > 0 &&
      (user === "Administrador" || user === "Empleado")
    ) {
      try {
        const url =
          urlmas.secure_url !==
          "https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg"
            ? await UploadPhoto(urlmas)
            : urlmas;
        socket.emit("categoriacrear", {
          url,
          producto: NewProducto,
        });
        setUrl({
          secure_url:
            "https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg",
          public_id: 0,
        });
        setagregar(!agregar);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Foto Actualizada",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Sucedio un Error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      if (NewProducto.Categoria.length < 1) {
        return Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Llenar Categoria",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      if (user !== "Administrador" || user !== "Empleado") {
        return Swal.fire({
          position: "top-end",
          icon: "error",
          title: "No tienes Permiso",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };
  return (
    <>
      {carga ? (
        <CircularProgress />
      ) : (
        <>
          <div className="form-agregar-producto d-flex flex-column" style={{overflowX:"auto"}}>
            <div className="d-flex flex-row justify-content-center mt-5 flex-wrap">
              <button
                onClick={() => cambiarCategoria("familia")}
                style={{
                  marginBottom: "30px",
                  color: crear === "familia" ? "#3D9A67" : "white",
                  border: "none",
                  padding: "9px 26px",
                  backgroundColor: crear === "familia" ? "white" : "#3D9A67",
                  ":hover": { opacity: "0.5", background: "#3D9A67" },
                }}
                className="me-3"
              >
                Familia
              </button>
              <button
                onClick={() => cambiarCategoria("categoria")}
                style={{
                  marginBottom: "30px",
                  color: crear === "categoria" ? "#3D9A67" : "white",
                  border: "none",
                  padding: "9px 26px",
                  backgroundColor: crear === "categoria" ? "white" : "#3D9A67",
                  ":hover": { opacity: "0.5", background: "#3D9A67" },
                }}
                className="me-3"
              >
                Categoria
              </button>
              <button
                onClick={() => cambiarCategoria("subCategoria")}
                style={{
                  marginBottom: "30px",
                  color: crear === "subCategoria" ? "#3D9A67" : "white",
                  border: "none",
                  padding: "9px 26px",
                  backgroundColor:
                    crear === "subCategoria" ? "white" : "#3D9A67",
                  ":hover": { opacity: "0.5", background: "#3D9A67" },
                }}
              >
                subCategoria
              </button>
            </div>
            <button
                onClick={cambiar}
                style={{
                  margin:"auto",
                  marginBottom: "30px",
                  color: "white",
                  border: "none",
                  padding: "9px 26px",
                  backgroundColor: "#3D9A67",
                  width:"240px",
                  ":hover": { opacity: "0.5", background: "#3D9A67" },
                }}
              >
                {agregar? <VscError fontSize='17px' className='me-2'/> :<VscDiffAdded fontSize='17px' className='me-2'/> } {agregar? "Cancelar": `Crear ${crear}` }
              </button>
            <div className="square-form">
              <table summary="Mis Productos">
                <thead>
                  <tr>
                    <th scope="col">
                      {crear === "familia" ? "Imagen" : "hijo"}
                    </th>
                    <th scope="col">Categoria</th>
                    <th scope="col">Mostrar</th>
                    <th scope="col">Accion</th>
                  </tr>
                </thead>
                <tbody>
                  {agregar === true ? (
                    <tr>
                      <td scope="row">
                        {crear === "familia" ? (
                          <img
                            className="hover"
                            src="https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg"
                            onClick={onFile}
                            alt="productousers"
                          ></img>
                        ) : (
                          <select
                            name="hijo"
                            onChange={onChangeMensaje}
                            value={NewProducto.hijo}
                          >
                            {Categorias.map((categoria) => (
                              <option>{categoria}</option>
                            ))}
                          </select>
                        )}
                      </td>
                      <td>
                        <input
                          className="tituloUsuario"
                          name="Categoria"
                          type="email"
                          value={NewProducto.Categoria}
                          maxLength={100}
                          onChange={onChangeMensaje}
                        />
                      </td>
                      <td>
                        <input
                          className="tituloUsuario"
                          name="mostrar"
                          type="checkbox"
                          value={NewProducto.mostrar}
                          onChange={onChangeMensaje}
                        />
                      </td>
                      <td>
                        <button
                          type="submit"
                          className="botonproductoagregar add "
                          onClick={onSubmit}
                        >
                          Publicar
                        </button>
                      </td>
                    </tr>
                  ) : null}
                  {productos.length > 0
                    ? productos.map((producto) => (
                        <CajaCategoriaSubidas
                          key={producto.cid}
                          Producto={producto}
                          history={history}
                          rol={user}
                          ruta={crear}
                          Categorias={Categorias}
                        />
                      ))
                    : null}
                </tbody>
              </table>
            </div>
            <input
              type="file"
              id="fileproducto"
              aria-label="File browser example"
              onChange={onFilesave}
            ></input>
          </div>
        </>
      )}
    </>
  );
};
