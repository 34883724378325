import React, { useState, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./PageTodosCategorias.scss";
import { fetchstoken } from "../helpers/fetchmetod";
import { useParams } from "react-router-dom";
import Cards from "./Cards";
import Footer from "./Footer";
import CircularProgress from "./CircularProgress";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import { CategoriasSeparadas } from "./CategoriasSeparadas";
import PaginadorMUI from "./PaginadorMUI";
import { ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
export function PageTodosCategorias({ history, location }) {
  let { categoria } = useParams();

  const search = location?.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);
  const cate = params.get("categoria"); // bar
  const subCate = params.get("subCategoria"); // bar
  const [Width, setWidth] = useState(window.innerWidth);
  const [carga, setCarga] = useState(true);
  const [productos, setState] = useState([]);
  const [categoris, setCategorias] = useState([]);
  const [cantidad, setCantidad] = useState(1);

  const [open, setOpen] = React.useState(true);

  const redirect = (data, categoria) => {
    history.push(
      `/categorias/${data}?${categoria ? "categoria=" + categoria : ""}`
    );
  };

  const cargarProductos = useCallback(async () => {
    const categoriasTodas = await fetchstoken(
      `categoria/${categoria}${search}`,
      {},
      "POST",
      cantidad
    );
    const categoriasdivision = await fetchstoken(
      `mostrarsubcategorias/${categoria}${search}`
    );
    const categorias = await fetchstoken(`categorias`);

    if (categoriasTodas.ok && categorias.ok && categoriasdivision.ok) {
      setCarga(false);
      setCategorias({
        categorias: categoriasdivision.categorias,
        subCategorias: categoriasdivision.subcategorias,
      });
      setState({
        productos: categoriasTodas.filtervar,
        categorias: categorias.familia,
        length: categoriasTodas.length,
      });
    }
  }, [setState, categoria, search, cantidad]);
  useEffect(() => {
    cargarProductos();
  }, [cargarProductos]);

  const cambiarTamañopantalla = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", cambiarTamañopantalla);
    return () => {
      window.removeEventListener("resize", cambiarTamañopantalla);
    };
  });

  return (
    <>
      {carga ? (
        <CircularProgress />
      ) : (
        <>
          <Helmet>
            <title>Categorias - {categoria}</title>
            <meta name="description" content={`todo sobre ${categoria}`} />
          </Helmet>
          <div className="page-categorias">
            <div className="d-flex flex-column">
              <div className="page-categorias-first d-flex flex-row">
                <h2 className="px-4 page-categorias-first-title m-0">
                  Categoria
                </h2>
                <p className="px-4 page-categorias-second-des">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div className="d-flex flex-row page-categorias-filter-container">
                <div className="page-categorias-filter">
                  <List
                    sx={{
                      width: "100%",
                    }}
                    component="nav"
                  >
                    {productos.categorias?.map((categoriaMap) => (
                      <>
                        <ListItemButton
                          key={categoriaMap}
                          sx={{ borderBottom: "1px solid black" }}
                          onClick={() => redirect(categoriaMap)}
                        >
                          <ListItemText primary={categoriaMap} />
                          {categoriaMap === categoria ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                        <Collapse
                          in={categoriaMap === categoria}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {categoris.categorias?.map((categiriSub) => (
                              <>
                                <ListItemButton
                                  sx={{
                                    pl: 4,
                                    borderBottom: "1px solid black",
                                  }}                        
                                onClick={() => redirect(categoriaMap,categiriSub)}
                                >
                                  <ListItemText primary={categiriSub} />
                                  {cate === categiriSub ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  )}
                                </ListItemButton>
                                <Collapse
                                  in={cate === categiriSub}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List component="div" disablePadding>
                                    {categoris.subCategorias?.map(
                                      (categiriSubcate) => (
                                        <>
                                          <ListItemButton
                                            sx={{
                                              pl: 8,
                                              borderBottom: "1px solid black",
                                            }}
                                            onClick={()=>history.push(`/categorias/${categoriaMap}?${(categiriSub)?"categoria="+categiriSub:""}&${(categiriSubcate)?"subCategoria="+categiriSubcate:""}`)}
                                          >
                                            <ListItemText
                                              primary={categiriSubcate}
                                            />
                                          </ListItemButton>
                                        </>
                                      )
                                    )}
                                  </List>
                                </Collapse>
                              </>
                            ))}
                          </List>
                        </Collapse>
                      </>
                    ))}
                  </List>
                </div>
                <div className="page-categorias-products">
                  <div className="d-flex flex-row flex-wrap">
                  {productos.productos?.map((produc) => (
                    <Cards
                      key={produc.pid}
                      history={history}
                      product={produc}
                    />
                  ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer /> 
        </>
      )}
    </>
  );
}
