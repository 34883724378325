import "./Imagenes.scss";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { fetchstoken } from "../helpers/fetchmetod";
import { useSelector } from "react-redux";
import { SocketContext } from "../redux/context/contextchat";
import Swal from "sweetalert2";
import { UploadPhoto } from "../helpers/cloudinaryUpload";
import Footer from "./Footer";
import CircularProgress from "./CircularProgress";
import { Helmet } from "react-helmet";
import { Typography } from "@mui/material";
import { teal } from "@mui/material/colors";

export function Imagenes() {
  const { socket } = useContext(SocketContext);
  const miusuario = useSelector((yo) => yo.infoUsuario.rol);
  const [imagenes, setImagenes] = useState([]);
  const [imagen, setImagen] = useState("largo");
  const [foto, setFoto] = useState("");
  const [urlfotos, setUrlfotos] = useState({
    secure_url:
      "https://res.cloudinary.com/dmgfep69f/image/upload/v1640536316/orgeial7kefv2dzsdqqt.webp",
    public_id: 0,
  });
  const [carga, setCarga] = useState(true);

  const cargarVideos = useCallback(async () => {
    const imagenes = await fetchstoken(`imagenes`);
    if (imagenes.ok) {
      setImagenes(imagenes.imagene.reverse());
      setCarga(false);
      return true;
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    cargarVideos();
  }, [cargarVideos]);

  const onChangeMensaje = (e) => {
    setImagen(e.target.value);
  };

  const guardarfotosync = async (e) => {
    e.preventDefault();
    try {
      const url =
        urlfotos.secure_url !==
        "https://res.cloudinary.com/dmgfep69f/image/upload/v1640536316/orgeial7kefv2dzsdqqt.webp"
          ? await UploadPhoto(urlfotos)
          : null;
      if (url === null) {
        return;
      }

      socket.emit("fotosGaleria", {
        url,
        imagen,
      });
      setUrlfotos({
        secure_url:
          "https://res.cloudinary.com/dmgfep69f/image/upload/v1640536316/orgeial7kefv2dzsdqqt.webp",
        public_id: 0,
      });
      socket.on("fotosGaleria", function (data) {
        if (data.ok) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Foto Subida",
            showConfirmButton: false,
            timer: 1500,
          });
          setImagenes([data.image,...imagenes]);
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Sucedio un Error",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    } catch (err) {
      console.log(err);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Sucedio un Error",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const onImagenEliminar = (iid, idfoto) => {
    try {
      Swal.fire({
        title: "Quieres eliminar la Foto?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "¡Sí, bórralo!",
      }).then((result) => {
        if (result.isConfirmed) {
          socket.emit("eliminarImagen", {
            iid,
            idfoto,
          });
        }
      });
      socket.on("eliminarImagen", function (data) {
        if (data.ok) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Foto elimada con exito",
            showConfirmButton: false,
            timer: 1500,
          });
          setImagenes(imagenes.filter((datus) => datus.iid !== data.iid));
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Sucedio un Error",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onFilesave = async (e) => {
    const file = e.target.files[0];
    setUrlfotos(file);
  };

  const onClick = () => {
    setFoto("");
  };

  const onClickImage = (imagen) => {
    if (foto.length === 0) {
      setFoto(imagen);
    } else {
      setFoto("");
    }
  };

  return (
    <>
      {carga ? (
        <CircularProgress />
      ) : (
        <>
          <Helmet>
            <title>FerreAgros - Imagenes </title>
            <meta name="description" content="Galeria de imagenes" />
          </Helmet>
          <div className="posicionFooter">
            {foto.length > 0 ? (
              <div className="mostrarimagenfondo">
                <img src={foto} alt="img"></img>
                <i className="bx bx-x" onClick={onClick}></i>
              </div>
            ) : null}

            {miusuario === "Administrador" || miusuario === "Empleado" ? (
              <form className="subirFotoGaleria" onSubmit={guardarfotosync}>
                <div>
                  <div className="mt-4">
                    <Typography
                      variant="h6"
                      gutterBottom
                      component="span"
                      sx={{ color: teal[900] }}
                      fontWeight={700}
                    >
                      Subir Imagen
                    </Typography>

                    <div className="file-select" id="src-file1">
                      <input
                      style={{cursor:"pointer"}}
                        type="file"
                        name="src-file1"
                        aria-label="Archivo"
                        onChange={onFilesave}
                      />
                    </div>
                  </div>
                </div>
                {urlfotos.public_id !== 0 ? (
                  <button type="submit" className="mt-4">Subir Foto</button>
                ) : null}
              </form>
            ) : null}
            <div className="header-page">
              <h1 className="header-page-title">Descubre la belleza de nuestros productos agrícolas y nuestra pasión por la agricultura</h1>
              <p className="header-page-parrafo mt-5">
              Bienvenido a FerreAgros, donde capturamos la esencia y la belleza de nuestros productos agrícolas en imágenes. Explora nuestra galería de fotos para sumergirte en el mundo de la agricultura y conocer más sobre nuestra empresa. Desde campos verdes hasta cosechas frescas, cada imagen refleja nuestro compromiso con la calidad y la excelencia. Únete a nosotros en este viaje visual y descubre la autenticidad y la frescura que ofrecemos en cada producto agrícola.
              </p>
            </div>
            <div className="gallery-image mt-5 mb-5">
              <div className="d-flex flex-row gallery-image-row">
                {imagenes?.map((imagen, i) =>
                  i % 2 === 0 ? (
                    <img
                      onClick={
                        miusuario === "Administrador" ||
                        miusuario === "Empleado"
                          ? () => onImagenEliminar(imagen.iid, imagen.idfoto)
                          : () => onClickImage(imagen.urlImagen)
                      }
                      src={imagen?.urlImagen}
                      alt={"image-"+ i}
                      className="gallery__img"
                    />
                  ) : null
                )}
              </div>
              <div className="d-flex flex-row gallery-image-row">
                
                {imagenes?.map((imagen, i) =>
                  i % 2 === 1 ? (
                    <img
                      onClick={
                        miusuario === "Administrador" ||
                        miusuario === "Empleado"
                          ? () => onImagenEliminar(imagen.iid, imagen.idfoto)
                          : () => onClickImage(imagen.urlImagen)
                      }
                      src={imagen?.urlImagen}
                      alt={"image-"+ i}
                      className="gallery__img"
                    />
                  ) : null
                )}
              </div>
            </div>

            <Footer></Footer>
          </div>
        </>
      )}
    </>
  );
}
