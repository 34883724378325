import "./Videos.scss";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { fetchstoken, fetchCToken } from "../helpers/fetchmetod";
import { useSelector } from "react-redux";
import { SocketContext } from "../redux/context/contextchat";
import Swal from "sweetalert2";
import Footer from "./Footer";
import CircularProgress from "./CircularProgress";
import { Helmet } from "react-helmet";

export function Video() {
  const { socket } = useContext(SocketContext);
  const miusuario = useSelector((yo) => yo.infoUsuario.rol);
  const [Video, setVideo] = useState({});
  const [Videos, setVideos] = useState([]);
  const [carga, setCarga] = useState(true);
  const [Width, setWidth] = useState(window.innerWidth);
  const [dataVideo, setDataVideo] = useState({
    url: "",
    descripcion: "",
    titulo: "",
  });
  const cargarVideos = useCallback(async () => {
    const videos = await fetchstoken(`videos`);
    if (videos.ok) {
      setVideos(videos.videos.reverse());
      setVideo(videos.videos[0]);
      setCarga(false);
      return true;
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    cargarVideos();
  }, [cargarVideos]);

  const cambiarTamañopantalla = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", cambiarTamañopantalla);
    return () => {
      window.removeEventListener("resize", cambiarTamañopantalla);
    };
  });

  const onChangeMensaje = (e) => {
    const { name, value } = e.target;
    setDataVideo({
      ...dataVideo,
      [name]: value,
    });
  };

  const onVideo = (video) => {
    setVideo(video);
  };

  const onVideoEliminar = (vid) => {
    try {
      Swal.fire({
        title: "estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "¡Sí, bórralo!",
      }).then((result) => {
        if (result.isConfirmed) {
          socket.emit("eliminarVideo", {
            vid,
          });
          setVideos(Videos.filter((videosb) => videosb?.vid !== vid));
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "video eliminado con exito",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const subirUrl = async (e) => {
    e.preventDefault();
    const resulturl = await fetchCToken(
      "videos",
      {
        url: dataVideo.url,
        descripcion: dataVideo.descripcion,
        titulo: dataVideo.titulo,
      },
      "POST"
    );
    if (resulturl.ok === true) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "video agregado correctamente",
        showConfirmButton: false,
        timer: 1500,
      });
      setVideos([resulturl.videos,...Videos]);
    }

    if (resulturl.ok === false) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: resulturl.msg,
        showConfirmButton: false,
        timer: 1500,
      });
    }

    setDataVideo({
      url: "",
      descripcion: "",
      titulo: "",
    });
  };
  return (
    <>
      {carga ? (
        <CircularProgress />
      ) : (
        <>
          <Helmet>
            <title>FerreAgros - Videos </title>
            <meta
              name="description"
              content="Encontraras videos relacionados a nuestra compañia, tambien podras como contactarnos y como pedir informacion de nuestros productos"
            />
          </Helmet>
          <div className="header-page">
            <h1 className="header-page-title">
            Descubre nuestra pasión por la agricultura y nuestros productos de calidad
            </h1>
            <p className="header-page-parrafo mt-5 ">
            Bienvenido a FerreAgros, donde combinamos la pasión por la agricultura con la excelencia en productos. Explora nuestros videos para conocer más sobre nuestra empresa y descubrir la amplia gama de productos agrícolas que ofrecemos. Desde cultivos frescos hasta productos procesados, nos enorgullece brindarte lo mejor de la tierra. ¡Únete a nosotros en este apasionante viaje agrícola!
            </p>
          </div>
          {miusuario === "Administrador" ||
          miusuario === "Empleado" ||
          miusuario === "Observador" ? (
            <>
              <form className="formUrl" onSubmit={subirUrl}>
                <div className="d-flex flex-column" style={{ flex: 1 }}>
                  <input
                    placeholder="Agregar video"
                    className="form-control w-75"
                    value={dataVideo.url}
                    name="url"
                    onChange={onChangeMensaje}
                    type="text"
                  />
                  <input
                    placeholder="Agregar titulo"
                    className="form-control w-75 mt-5"
                    value={dataVideo.titulo}
                    name="titulo"
                    onChange={onChangeMensaje}
                    type="text"
                  />
                  <textarea
                    placeholder="Agregar descripcion"
                    className="mt-5 form-control w-75"
                    value={dataVideo.descripcion}
                    name="descripcion"
                    onChange={onChangeMensaje}
                    type="text"
                  />
                </div>
                <button type="submit" style={{ height: "50px" }}>
                  Subir Video
                </button>
              </form>
            </>
          ) : null}

          <div className="pageVideos mb-5">
            <div className="videoSeleccionado">
              {Videos.length > 0 ? (
                <iframe
                  height={
                    Width > 1280
                      ? 548
                      : Width > 780
                      ? (((Width / 5) * 4 - 50) / 560) * 315
                      : (Width / 560) * 315
                  }
                  src={"https://www.youtube.com/embed/" + Video.urlvideo}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : null}
              <h2 className="mt-5 poppins fw-bold">{Video?.titulo}</h2>
              <p className="mt-4 poppins">{Video?.descripcion}</p>
            </div>
            <div className="VideosEspera">
              {Videos.map((video) => (
                <div key={video.urlvideo} id={video.urlvideo}>
                  <iframe
                    height={
                      Width > 1280
                        ? 150
                        : Width > 780
                        ? (((Width / 5) * 1) / 560) * 315
                        : ((Width * 0.6) / 560) * 315
                    }
                    src={"https://www.youtube.com/embed/" + video?.urlvideo}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <div>
                    <p className="botonvervideo" onClick={() => onVideo(video)}>
                      ver video
                    </p>
                    {miusuario === "Administrador" ||
                    miusuario === "Empleado" ||
                    miusuario === "Observador" ? (
                      <p
                        className="botonborrarvideo"
                        onClick={() => onVideoEliminar(video?.vid)}
                      >
                        eliminar video
                      </p>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Footer></Footer>
        </>
      )}
    </>
  );
}
