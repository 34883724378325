import React, {useEffect,useCallback, createContext} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { regenerate,loginstate,actualizarfoto, subidafotos, borrarfotos,agregarfotobanner,agregarfotoInformacion,agregarInformacionText } from '../actions/auth';
import { user,obtenermensajes,exitChat,Cargarmensajeschat,añadirUsuario,eliminarUsuario,modificarusuario } from '../actions/chat';
import {  eliminarorden ,eliminarpedido, subirOrden} from '../actions/ordenar';
import { eliminarproducto ,añadirproducto,eliminarcategoria,modificarCategoria,añadircategoria, modificarproducto, agregarfotoproducto, eliminarfotoproducto, añadirProductoproducto,eliminarparrafoproducto,cargarventas, cargarcompras} from '../actions/productos';
import { fetchCToken } from '../../helpers/fetchmetod';
import { useSocket } from "../../SocketsConnection/useSocket";
import Swal from 'sweetalert2'

export const SocketContext = createContext();
export const SocketProvider = ({ children }) => {

    const dispatch = useDispatch();
    const {  socket ,  conectarSocket, desconectarSocket } = useSocket('https://round-motley-chili.glitch.me/');

    const verificartoken = useCallback(
     async() => {
       const token = localStorage.getItem('token');
       if(!token){
         dispatch(regenerate());
         return  false;
       }
       const res = await fetchCToken('renovacion');
       if(res.ok){
         res.usuario.online = true;
         localStorage.getItem('token',res.token);
         const {__v,...usuario} = res.usuario;
         dispatch(loginstate(usuario));
         return  true;
       }else{
         dispatch(regenerate());
         return  false;
       }
     }, [dispatch],
   )



     useEffect(() => {
       verificartoken();
     }, [verificartoken])

    const state = useSelector(state => state.infoUsuario);

    
    useEffect(() => {
        if ( state.online ) {
            conectarSocket();
        }
    }, [ state.online, conectarSocket ]);

    useEffect(() => {
        if ( !state.online ) {
            desconectarSocket();
        }
    }, [ state.online, desconectarSocket ]);

    useEffect(() => {
  
    socket?.on( 'lista-usuarios', (usuarios) => {
          dispatch(user(usuarios));
      })
  }, [ socket, dispatch]);
  
  useEffect(() => {
  
    socket?.on( 'lista-vendidos', (ventas) => {
          dispatch(cargarventas(ventas));
      })
  }, [ socket, dispatch]);


  

    useEffect(() => {
      socket?.on( 'lista-compras', (compras) => {
            dispatch(cargarcompras(compras));
        })
    }, [ socket, dispatch]);
    
    useEffect(() => {
      socket?.on( 'notificacion-venta', () => {
       new Notification("tuviste una venta")
        })
    }, [ socket, dispatch]);
  useEffect(() => {
  
    socket?.on( 'productomodificar', (producto) => {
      dispatch(modificarproducto(producto));
    })
  }, [ socket, dispatch]);

  useEffect(() => {
  
    socket?.on( 'categoriamodificar', (producto) => {
      dispatch(modificarCategoria(producto));
    })
  }, [ socket, dispatch]);

  useEffect(() => {
    socket?.on( 'usuariomodificar', (Usuario) => {
      dispatch(modificarusuario(Usuario));
    })
  }, [ socket, dispatch]);

  useEffect(() => {
  
    socket?.on( 'productoeliminar', (pid) => {
      dispatch(eliminarproducto(pid));
    })
  }, [ socket, dispatch]);

  useEffect(() => {
  
    socket?.on( 'categoriaeliminar', (cid) => {
      dispatch(eliminarcategoria(cid));
    })
  }, [ socket, dispatch]);
  
  useEffect(() => {
  socket?.on( 'usuarioEliminar', (uid) => {
    dispatch(eliminarUsuario(uid));
  })
}, [ socket, dispatch]);

  useEffect(() => {
  
    socket?.on( 'subirfotoadicionalproducto', (producto) => {
      dispatch(agregarfotoproducto(producto));
    })
  }, [ socket, dispatch]);

  useEffect(() => {
  
    socket?.on( 'fotoBanner', (url) => {
      dispatch(agregarfotobanner(url));
    })
  }, [ socket, dispatch]);

  useEffect(() => {
  
    socket?.on( 'fotoInformacion', (urlconver,foto) => {
      dispatch(agregarfotoInformacion({urlconver,foto}));
    })
  }, [ socket, dispatch]);

  useEffect(() => {
  
    socket?.on( 'informacionText', (modificarInfo) => {
      dispatch(agregarInformacionText(modificarInfo));
    })
  }, [ socket, dispatch]);

useEffect(() => {
  socket?.on( 'recibidoproductosolicitud',async (data) => {
    const res = await fetchCToken(`chat/${data}`);
    dispatch(Cargarmensajeschat(res.mensajes));
  })
}, [ socket, dispatch,state.uid]);

  useEffect(() => {
  
    socket?.on( 'producto', ({ok,msg,...product}) => {
      if(ok){
        dispatch(añadirproducto(product));
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Producto subido',
          showConfirmButton: false,
          timer: 1500
        })
      }
      else{
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: msg,
          showConfirmButton: false,
          timer: 1500
        })
      }

    })
  }, [ socket, dispatch]);

  useEffect(() => {
  
    socket?.on( 'categoriacrear', (categoria) => {
      dispatch(añadircategoria(categoria));
    })
  }, [ socket, dispatch]);

  useEffect(() => {
  socket?.on( 'newusuario', (usuario) => {
    dispatch(añadirUsuario(usuario));
  })
}, [ socket, dispatch]);


  useEffect(() => {
  
    socket?.on( 'fotoproductoeliminar', (urlconver) => {
      dispatch(eliminarfotoproducto(urlconver));
    })
  }, [ socket, dispatch]);

  useEffect(() => {
  
    socket?.on( 'productoparrafoeliminar', ({error,index}) => {
      if(error){
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: "no se pudo publicar el parrafo",
          showConfirmButton: false,
          timer: 1500
        })
      }else{
        dispatch(eliminarparrafoproducto(index));
      }
    })
  }, [ socket, dispatch]);

  useEffect(() => {
  
    socket?.on( 'resetchat', () => {
      dispatch(exitChat());
    })
  }, [ socket, dispatch]);

  useEffect(() => {
  
    socket?.on( 'subirparrafonuevo', (Parrafo) => {
      dispatch(añadirProductoproducto(Parrafo));
    })
  }, [ socket, dispatch]);

  useEffect(() => {
    socket?.on( 'mensaje', (mensaje) => {
    dispatch(obtenermensajes(mensaje));
    })
}, [ socket , dispatch]);

useEffect(() => {
  socket?.on( 'orden', (orden) => {
          dispatch(subirOrden(orden));
  })
}, [ socket , dispatch]);

useEffect(() => {
  socket?.on( 'fotouseradicional', ({urlfoto,uidfoto}) => {
 const urlcompleta = {urlfoto,uidfoto}
     dispatch(subidafotos(urlcompleta));
  })
}, [ socket , dispatch]);

useEffect(() => {
  socket?.on( 'fotousereliminar', (uidfoto) => {
     dispatch(borrarfotos(uidfoto));
  })
}, [ socket , dispatch]);

useEffect(() => {
  socket?.on( 'fotouser', (url) => {
  dispatch(actualizarfoto(url));
  })
}, [ socket , dispatch]);


useEffect(() => {
    socket?.on( 'eliminarorden', (oid) => {
            dispatch(eliminarorden(oid));
            dispatch(eliminarpedido(oid));
    })
}, [ socket , dispatch, state.uid]);
    return (
        <SocketContext.Provider value={{ socket, online: state.online }}>
            { children }
        </SocketContext.Provider>
    )
}
