import React from "react";
import "./Carrito.scss";
import { Link } from "react-router-dom";
import Cajacarrito from "./Cajacarrito";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const Carrito = ({ history }) => {
  const carritoproducto = useSelector((carritos) => carritos.productos.carrito);
  let total = [0];
  const reducer = (accumulator, curr) => accumulator + curr;
  if (carritoproducto.length > 0) {
    total = carritoproducto.map((dato) => parseInt(dato.detalles[0].Precio));
  }
  return (
    <>
      <Helmet>
        <title>FerreAgros - Carrito de compras</title>
        <meta
          name="description"
          content="selecciona todos los productos que te interesan para poder contactar contigo"
        />
      </Helmet>
      <div className="fondocarrito">
        <div className="conteinerproductoseleccionado">
          <div className="flexro">
            <span className="botoncarrito opacityOprimer">
              Carrito({carritoproducto.length})
            </span>
            <h2 className="carrito-Price">
              Total:{" "}
              {"$" + new Intl.NumberFormat().format(total.reduce(reducer))}
            </h2>
          </div>
          <hr className="hr-carrito"></hr>

          {carritoproducto.map((carro) => (
            <Cajacarrito
              history={history}
              producto={carro.titulo}
              precio={carro.detalles[0].Precio}
              descripsion={carro.textdescripsion[0]}
              urlfoto={
                carro.fotosdescripsion[0]?.secure_url
                  ? carro.fotosdescripsion[0].secure_url
                  : "https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg"
              }
              id={carro.pid}
            ></Cajacarrito>
          ))}
          {carritoproducto?.length === 0 ? (
            <>
              <div className="comprarbotoncarrito">
                <p className="comprarbotoncarrito-parrafo">Selecciona Tus Productos Favoritos{" "}</p>
                <Link to="/inicio" className="botoncarrito">
                  Ver todos los productos
                </Link>
              </div>
            </>
          ) : null}
          {carritoproducto.length > 0 ? (
            <button className="comprarbotoncarrito">
              <a
                href={`https://api.whatsapp.com/send?phone=573166229671&text=hola buenos dias, estoy interesado en los siguientes productos: ${carritoproducto.map(
                  (producto) =>
                    ` ${producto.titulo} a $${Intl.NumberFormat().format(
                      producto.detalles[0].Precio
                    )}`
                )} me gustaria mas informacion acerca de ellos`}
              >
                Cotizar
              </a>
            </button>
          ) : null}
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Carrito;
