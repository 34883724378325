import React, {useContext}from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Register from '../componentes/Register';
import Listprod from '../componentes/Listprod';
import Producto from '../componentes/Producto';
import Inicio from '../componentes/Inicio';
import Carrito from '../componentes/Carrito';
import { Rutaprivada } from '../Rutas/Rutaprivada';
import { Rutapublica } from '../Rutas/Rutapublica';
import { Rutaprivadadministrador } from '../Rutas/Rutaprivadadministrador';
import { SocketContext } from '../redux/context/contextchat'
import Nosotros from '../componentes/Perfil';
import { CrearProducto } from '../componentes/CrearProducto';
import { CrearCategoria } from '../componentes/CrearCategoria';
import { useSelector} from 'react-redux';
import { PageTodosCategorias } from '../componentes/PageTodosCategorias';
import { Video } from '../componentes/Videos';
import { Imagenes } from '../componentes/Imagenes';
import HeaderMUI from '../componentes/HeaderMUI';
import iniciarSesionMUI from '../componentes/Iniciar-sesion-MUI';
import UsuariosMUI from '../componentes/UsuariosMUI';

export default function Rutas() {
    const miusuario =  useSelector(yo => yo.infoUsuario.rol);
    const {online} = useContext(SocketContext);
    return (
        <Router>
  <HeaderMUI />
  <Switch>
  <Rutapublica isAuthenticated={online} path="/login" component={ iniciarSesionMUI }/>
  <Rutapublica isAuthenticated={online} path="/register" component={ Register }/>
  <Route exact path="/busqueda/:busqueda" component={ Listprod }/>
  <Route exact path="/producto/:id" component={ Producto }/>
  <Rutaprivada isAuthenticated={online}  path="/crearproducto" component={ CrearProducto }/>
  <Rutaprivada isAuthenticated={online}  path="/crearCategorias/:crear" component={ CrearCategoria }/>
  <Route exact path="/perfil" component={ Nosotros }/>
  <Route exact path="/" component={ Inicio }/>
  <Route isAuthenticated={online} path='/carrito' component={Carrito}/>
  <Rutaprivadadministrador admin={miusuario} isAuthenticated={online} path='/usuarios' component={UsuariosMUI}/>
  <Route isAuthenticated={online} path='/categorias/:categoria' component={PageTodosCategorias}/>
  <Route path='/video' component={Video}/>
  <Route path='/imagenes' component={Imagenes}/>
  <Redirect from='/' to='/'/>
  </Switch>
  </Router>
    )
}
