import React,{useState,useContext} from 'react';
import { SocketContext } from '../redux/context/contextchat'
import Swal from 'sweetalert2'
import "./CajaUsuariosubidos.scss";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { teal,red } from '@mui/material/colors';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const CajaUsuariosubidosMUI = ({Producto,history}) => {
    const {socket} = useContext(SocketContext);
    const initialstate = Producto;
    const [state, setState] = useState(initialstate);
    const [Modificar, setModificar] = useState(false);
    const eliminarUsuario = () =>{
        Swal.fire({
            title: 'estas seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, bórralo!'
          }).then((result) => {
            if (result.isConfirmed) {
                socket.emit('usuarioEliminar',{
                    Producto
                    });
        
                 Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Usuario eliminado con exito',
                        showConfirmButton: false,
                        timer: 1500
                      })
            }
          })
    }

    const botonmodificar = () =>{
      setModificar(!Modificar);
      if(false === Modificar){
        setState(initialstate);
      }
    }
    const guardar = async() =>{
      if (Modificar === true) {
        socket.emit('usuariomodificar',{
          usuario:state          
        });
        
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
          Toast.fire({
            icon: 'success',
            title: 'Informacion Guardada'
          })
          
          setModificar(!Modificar)

    }  
    }
    const onChangeMensaje = (e) => {
        const { name, value } = e.target;
        setState({
          ...state,
          [name]: value,
        });
      };


    return (
      <>
<StyledTableRow key={Producto.nombre}>
    {(Modificar === false)?<StyledTableCell align="center" component="th" scope="row">{Producto.nombre}</StyledTableCell>:null}
    {(Modificar === true)?<StyledTableCell align="center" component="th" scope="row"><TextField id="standard-basic" label="Nombre" variant="standard" name='nombre' value={state.nombre} onChange={onChangeMensaje}/></StyledTableCell>:null}
    {(Modificar === false)?<StyledTableCell align="center" component="th" scope="row">{Producto.correo}</StyledTableCell>:null}
    {(Modificar === true)?<StyledTableCell align="center" component="th" scope="row"><TextField type="email" id="standard-basic" label="Correo" variant="standard" name='correo' value={state.correo} onChange={onChangeMensaje}/></StyledTableCell>:null}
    {(Modificar === false)?<StyledTableCell align="center">******************</StyledTableCell>:null}
    {(Modificar === true)?<StyledTableCell align="center" component="th" scope="row"><TextField type="password" id="standard-basic" label="Password" variant="standard" name='password' value={state.password} onChange={onChangeMensaje}/></StyledTableCell>:null}
        
        {(Modificar === false)?<StyledTableCell align="center">{Producto.rol}</StyledTableCell>:null}
    {(Modificar === true)? <StyledTableCell align="center" component="th" scope="row"> 
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Rol</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={state.rol}
          onChange={onChangeMensaje}
          label="Rol"
          name='rol'
        >
          <MenuItem value='Administrador'>ADMIN</MenuItem>
          <MenuItem value="Empleado">Empleado</MenuItem>
          <MenuItem value="Observador">Observador</MenuItem>
        </Select>
      </FormControl></StyledTableCell>:null}
    {(Modificar === false)?<StyledTableCell align="center">
                            <IconButton aria-label="delete" size="large" color="#4C4A4A" onClick={botonmodificar}>
                            <EditIcon fontSize="inherit" color="#4C4A4A" />
                            </IconButton>
                            <IconButton aria-label="delete" size="large" color='#4C4A4A' onClick={eliminarUsuario}>
                            <DeleteIcon fontSize="inherit" />
                            </IconButton>
                            </StyledTableCell>:null}
    {(Modificar === true)?<StyledTableCell align="center" sx={{flexDirection:"column",display:"flex"}}>
    <Button onClick={guardar} variant="contained" startIcon={<EditIcon /> } sx={{mb:"10px", backgroundColor:"#4C4A4A",":hover":{backgroundColor: "#4C4A4A"}}}>Editar</Button>
    <Button onClick={botonmodificar} variant="contained" startIcon={<CancelIcon /> } sx={{mb:"10px", backgroundColor:"#4C4A4A",":hover":{backgroundColor: "#4C4A4A"}}}>Cancelar</Button>
                            </StyledTableCell>:null}
    </StyledTableRow>
</>
    );
}
export default CajaUsuariosubidosMUI;
