import "./Perfil.scss";
import "./quienes-somos.scss";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { UploadPhoto } from "../helpers/cloudinaryUpload";
import React, { useCallback, useEffect, useContext, useState } from "react";
import { SocketContext } from "../redux/context/contextchat";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { agregarInformacion } from "../redux/actions/auth";
import { fetchstoken } from "../helpers/fetchmetod";
import { TextField, Button } from "@mui/material/";
import Somos from "./images/banner-somos.png";
import Perfil from "./images/perfil-somos.png";

function Nosotros() {
  const dispatch = useDispatch();
  const { socket } = useContext(SocketContext);
  const banner = useSelector((yo) => yo.infoUsuario.fotos);
  const rol = useSelector((yo) => yo.infoUsuario.rol);

  const [modificar, setModificar] = useState(banner?.[0]);
  const [modificarMostrar, setModificarMostrar] = useState({
    mostrar1: false,
    mostrar2: false,
    mostrar3: false,
  });

  const onChangeEdit = (e) => {
    const { name, value } = e.target;
    setModificar({
      ...modificar,
      [name]: value,
    });
  };

  const cargarProductos = useCallback(async () => {
    const categoriasTodas = await fetchstoken(`mostrar`);
    if (categoriasTodas.ok) {
      dispatch(agregarInformacion(categoriasTodas.fotos));
      return true;
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    cargarProductos();
  }, [cargarProductos]);

  useEffect(() => {
    setModificar(banner?.[0]);
  }, [banner]);

  const onFilefoto1 = () => {
    document.querySelector("#fotoSomos").click();
  };
  const onFilefoto2 = () => {
    document.querySelector("#fotoVision").click();
  };
  const onFilefoto3 = () => {
    document.querySelector("#fotoFundacion").click();
  };

  const onFilesavefoto = async (e, foto) => {
    const file = e.target.files[0];
    try {
      const url = await UploadPhoto(file);
      socket.emit("fotoInformacion", {
        url,
        idFoto:
          foto === "urlfotosomos"
            ? banner[0].idfotosomos
            : foto === "urlVision"
            ? banner[0].idVision
            : foto === "urlFundacion"
            ? banner[0].idFundacion
            : null,
        foto,
      });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Foto Subida",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      console.log(err);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Sucedio un Error Al Subir La Foto",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const onInformationSave = () => {
    socket.emit("informacionText", {
      modificar,
    });
    setModificarMostrar({
      mostrar1: false,
      mostrar2: false,
      mostrar3: false,
    });
  };
  return (
    <>
      <Helmet>
        <title>FerreAgros - Historia</title>
        <meta
          name="description"
          content="Todo lo que necesitas saber de nuestros origenes y que nos motiva a seguir adelante"
        />
      </Helmet>
      {modificarMostrar.mostrar1 === true ||
            modificarMostrar.mostrar2 === true ||
            modificarMostrar.mostrar3 === true ? (
              <div className="d-flex justify-content-center">
                {" "}
                <Button
                  variant="text"
                  sx={{
                    background: "#3D9A67",
                    color: "white",
                    marginTop: "25px",
                    padding: "10px 20px",
                    textTransform: "capitalize",
                    ":hover":{background:"rgba(0, 77, 64,0.8)"}
                  }}
                  onClick={(rol === 'Administrador' || rol === 'Empleado')?onInformationSave:null}
                  className="me-4"
                >
                  Guardar Informacion
                </Button>
                <Button
                  variant="text"
                  sx={{
                    background: "red",
                    color: "white",
                    marginTop: "25px",
                    padding: "10px 20px",
                    textTransform: "capitalize",
                    ":hover":{background:"#ff000063"}
                  }}
                  onClick={() =>{
                    setModificar(banner[0]);
                    setModificarMostrar({
                      mostrar1: false,
                      mostrar2: false,
                      mostrar3: false,
                    });
                  }
                  }
                >
                  Cancelar
                </Button>
              </div>
            ) : null}
      <div className="quienes-somos-renew d-flex flex-row justify-content-between">
        <div className="quienes-somos-renew-contenedor-image">
          <img 
            onClick={
            rol === "Administrador" || rol === "Empleado"
              ? onFilefoto1
              : null
            }
            className="quienes-somos-renew-contenedor-image-image"
            src={banner?.[0].urlfotosomos}
            alt="imagen de quienes somos"
          />
          <input
              type="file"
              id="fotoSomos"
              aria-label="File browser example"
              onChange={(e) => onFilesavefoto(e, "urlfotosomos")}
              style={{ display: "none" }}
            ></input>
        </div>
        <div className="quienes-somos-renew-contenedor px-5">
          <h1 className="quienes-somos-renew-contenedor-title">
            Quienes somos
          </h1>
          {modificarMostrar.mostrar1 ? (
              <TextField
                name="somosText"
                value={modificar.somosText}
                onChange={onChangeEdit}
                className="modificacionTextInformacion"
                fullWidth
              />
            ) : (<p className="quienes-somos-renew-contenedor-parrafo" onClick={(rol === 'Administrador' || rol === 'Empleado')?() =>
            setModificarMostrar({ ...modificarMostrar, mostrar1: true }):null
          }>
          {modificar?.somosText}
          </p>
            )}
          <div className="d-flex flex-row quienes-somos-renew-contenedor-perfil mb-5 mt-5">
            <div className="d-flex flex-column justify-content-center">
              <h3 className="quienes-somos-renew-contenedor-title-perfil m-0">
                Alejandro Ángel
              </h3>
              <p className="quienes-somos-renew-contenedor-parrafo-perfil m-0">
                Fundador
              </p>
            </div>
          </div>
          <h2 className="quienes-somos-renew-contenedor-title">Visión</h2>
          {modificarMostrar.mostrar2 ? (
              <TextField
                name="visionText"
                value={modificar.visionText}
                onChange={onChangeEdit}
                className="modificacionTextInformacion"
                sx={{ background: "white" }}
                fullWidth
              />
            ) : (<p className="quienes-somos-renew-contenedor-parrafo"  onClick={(rol === 'Administrador' || rol === 'Empleado')?() =>
            setModificarMostrar({ ...modificarMostrar, mostrar2: true }):null
          }>
          {modificar?.visionText}
          </p>)}
        </div>
      </div>
      <div className="alert-perfil mt-5 mb-5">
        <h2 className="alert-perfil-titulo">Fundación</h2>
        {modificarMostrar.mostrar3 ? (
              <TextField
                name="FundacionText"
                value={modificar.FundacionText}
                onChange={onChangeEdit}
                className="modificacionTextInformacion"
                fullWidth
              />
            ) : (<p className="alert-perfil-perfil" onClick={(rol === 'Administrador' || rol === 'Empleado')?() =>
            setModificarMostrar({ ...modificarMostrar, mostrar3: true }):null
          }>
        {modificar?.FundacionText}
        </p>)}
      </div>

      <Footer />
    </>
  );
}

export default Nosotros;
