import { types } from "../types/authLogin";
const initstate = {
  nombre: "",
  correo: "",
  uid: "",
  rol: "",
  online: false,
  fotos: [
    {
      FundacionText: "",
      idBanner: "",
      idFundacion: "",
      idVision: "",
      idfotosomos: "",
      ifid: "",
      somosText: "",
      urlBanner: "",
      urlFundacion: "",
      urlVision: "",
      urlfotosomos: "",
      visionText: "",
    },
  ],
};
function reducer(state = initstate, action) {
  switch (action.type) {
    case types.login:
      return {
        ...state,
        nombre: action.payload.nombre,
        correo: action.payload.correo,
        uid: action.payload.uid,
        rol: action.payload.rol,
        password: action.payload.password,
        online: action.payload.online,
        fotos: action.payload.fotos,
      };
    case types.UpdateFoto:
      return {
        ...state,
        urlfoto: action.payload,
      };
    case types.RecibirFotos:
      return {
        ...state,
        fotosdescripsion: [...state.fotosdescripsion, action.payload],
      };
    case types.Eliminarfoto:
      return {
        ...state,
        fotosdescripsion: state.fotosdescripsion.filter(function (foto) {
          return action.payload !== foto.uidfoto;
        }),
      };
    case types.agregarFotoBanner:
      return {
        ...state,
        fotos: [
          {
            ...state.fotos[0],
            urlBanner: action.payload.secure_url,
            idBanner: action.payload.public_id,
          },
        ],
      };
      case types.agregarInformacionText:
      return {
        ...state,
        fotos: [
          {
            ...action.payload
          },
        ],
      };
    case types.agregarFotosInformacion:
      if(action.payload.foto === "urlfotosomos"){
        return {
          ...state,
          fotos: [
            {
              ...state.fotos[0],
              urlfotosomos: action.payload.urlconver.secure_url,
              idfotosomos: action.payload.urlconver.public_id,
            },
          ],
        };
      }
      if(action.payload.foto === "urlVision"){
        return {
          ...state,
          fotos: [
            {
              ...state.fotos[0],
              urlVision: action.payload.urlconver.secure_url,
              idVision: action.payload.urlconver.public_id,
            },
          ],
        };
      }
      if(action.payload.foto === "urlFundacion"){
        return {
          ...state,
          fotos: [
            {
              ...state.fotos[0],
              urlFundacion: action.payload.urlconver.secure_url,
              idFundacion: action.payload.urlconver.public_id,
            },
          ],
        };
      }
      return
    case types.agregarInformacion:
      return {
        ...state,
        fotos: {
          ...action.payload,
        },
      };
    case types.regenerate:
      return initstate;
    default:
      return state;
  }
}

export default reducer;
