export const types = {
  login : '[Auth] login',
  regenerate : '[Auth] regenerate',
  logout: '[Auth] logout',
  UpdateFoto: '[Auth] UpdateFoto',
  RecibirFotos: '[Auth] RecibirFotos',
  Eliminarfoto: '[Auth] eliminarfoto',
  agregarFotoBanner:'[Auth] agregarFotoBanner',
  agregarInformacion:'[Auth] agregarInformacion',
  agregarFotosInformacion:'[Auth] agregarFotosInformacion',
  agregarInformacionText:'[Auth] agregarInformacionText'
}