export const types = {
    cargarProductos: '[PRODUCTOS] cargarProductos',
    agregarProducto: '[PRODUCTOS] agregarProducto',
    eliminaProducto: '[PRODUCTOS] eliminaProducto',
    modificarproducto: '[PRODUCTOS] modificarproducto',
    agregarFotoProducto: '[PRODUCTOS] agregarFotoProducto',
    cargarfotosproductos: '[PRODUCTOS] cargarfotosproductos',
    eliminarfotoproducto: '[PRODUCTOS] eliminarfotoproducto',
    cargarparrafoproducto: '[PRODUCTOS] cargarparrafoproducto',
    añadirProductoproducto: '[PRODUCTOS] añadirProductoproducto',
    eliminarparrafoproducto: '[PRODUCTOS] eliminarparrafoproducto',
    reset: '[PRODUCTOS] reset',
    cargarcompras: '[PRODUCTOS] cargarcompras',
    cargarventas: '[PRODUCTOS] cargarventas',
    cargarcategorias: '[PRODUCTOS] cargarcategorias',
    añadircategoria: '[PRODUCTOS] añadircategoria',
    modificarCategoria: '[PRODUCTOS] modificarCategoria',
    eliminarcategoria:'[PRODUCTOS] eliminarcategoria',
    añadirCarrito: '[PRODUCTOS] añadirCarrito',
    eliminarCarrito: '[PRODUCTOS] eliminarCarrito',
} 