import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import "./Producto.scss";
import React, { useState, useEffect, useCallback, useContext } from "react";
import Slider from "react-slick";
import Footer from "./Footer";
import { useParams, useRouteMatch } from "react-router-dom";
import { fetchstoken } from "../helpers/fetchmetod";
import { UploadPhoto } from "../helpers/cloudinaryUpload";
import { SocketContext } from "../redux/context/contextchat";
import {  AiOutlineShareAlt } from "react-icons/ai";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import {
  añadirfotosproducto,
  cargarparrafoproducto,
  resetpro,
  añadirCarrito,
} from "../redux/actions/productos";
import CajaProductoFotos from "./CajaProductoFotos";
import ParrafosProducto from "./ParrafosProductos";
import CircularProgress from "./CircularProgress";
import Cards from "./Cards";
import Typography from "@mui/material/Typography";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";

function Producto({ history }) {
  const dispatch = useDispatch();

  const miuid = useSelector((yo) => yo.infoUsuario);
  const estado = useSelector((fotos) => fotos.productos);
  const fotos = estado.fotosproducto;
  const parrafosprodcuto = estado.parrafosproducto;
  let { id } = useParams();
  const match = useRouteMatch();
  const { socket } = useContext(SocketContext);
  const [categoriaencontradas, setCategoria] = useState([]);
  const [carga, setCarga] = useState(true);
  const [Width, setWidth] = useState(window.innerWidth);
  const [Parrafoactivo, setParrafoActivo] = useState(false);
  const [Parrafo, setParrafo] = useState("");
  const [Foto, setFoto] = useState({});

  const [agregarfotos, setAgregarfotos] = useState({
    secure_url:
      "https://res.cloudinary.com/dmgfep69f/image/upload/v1640536316/orgeial7kefv2dzsdqqt.webp",
    public_id: 0,
  });
  const [state, setState] = useState({
    ok: true,
    textdescripsion: ["asd"],
    fotosdescripsion: [
      {
        secure_url:
          "https://cdn.pocket-lint.com/r/s/1200x/assets/images/142227-phones-review-iphone-x-review-photos-image1-ahdsiyvum0.jpg",
      },
    ],
    titulo: "ada",
    detalles: [
      {
        Age: "",
        Categoria: "",
        Familia: "",
        subCategoria: "",
        Precio: "0",
        PrecioD: "0",
      },
    ],
  });
  const onChangeMensaje = (e) => {
    setParrafo(e.target.value);
  };

  const agregarparrafo = () => {
    setParrafoActivo(!Parrafoactivo);
  };

  const cargarProductos = useCallback(async () => {
    try {
      const infoproducto = await fetchstoken(`producto/${id}`);
      const buscarcategoria = await fetchstoken(
        `mostrar/${infoproducto.detalles[0].Familia}`
      );

      setCategoria(buscarcategoria.filtervar);
      setState(infoproducto);
      dispatch(añadirfotosproducto(infoproducto.fotosdescripsion));
      dispatch(cargarparrafoproducto(infoproducto.textdescripsion));
      setFoto(infoproducto.fotosdescripsion[0]);
      setCarga(false);
    } catch (error) {
      history.push("/");
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "El producto no se encontro",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [setState, dispatch, id, history]);

  useEffect(() => {
    cargarProductos();
    return () => {
      dispatch(resetpro());
    };
  }, [cargarProductos, dispatch]);

  useEffect(() => {
    const chatscrollabajo = document.querySelector(".inicioPage");
    chatscrollabajo?.scrollIntoView({
      behavior: "smooth",
    });
  });

  const onFilefoto = () => {
    document.querySelector("#fileperfilmas").click();
  };

  const onFilesavefoto = async (e) => {
    const file = e.target.files[0];
    setAgregarfotos(file);
  };

  const onSubmit = async () => {
    if (fotos.length) {
      try {
        const url =
          agregarfotos.secure_url !==
          "https://res.cloudinary.com/dmgfep69f/image/upload/v1640536316/orgeial7kefv2dzsdqqt.webp"
            ? await UploadPhoto(agregarfotos)
            : agregarfotos;
        socket.emit("subirfotoadicionalproducto", {
          url,
          pid: state.pid,
        });
        setAgregarfotos({
          secure_url:
            "https://res.cloudinary.com/dmgfep69f/image/upload/v1640536316/orgeial7kefv2dzsdqqt.webp",
          public_id: 0,
        });
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Foto Subida",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Sucedio un Error Al Subir La Foto",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "No se Aceptan Mas de 5 fotos",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const cambiarTamaño = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", cambiarTamaño);
    return () => {
      window.removeEventListener("resize", cambiarTamaño);
    };
  });

  const settings = {
    arrows: Width > 355 ? true : false,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: Width > 1348 ? 4 : Width > 1057 ? 3 : Width > 740 ? 2 : 1,

    slidesToScroll: Width > 1348 ? 4 : Width > 1057 ? 3 : Width > 740 ? 2 : 1,
  };

  const onOverFoto = (url) => {
    setFoto({
      secure_url: url.nativeEvent.srcElement.currentSrc,
    });
  };

  const reset = () => {
    setAgregarfotos({
      secure_url:
        "https://res.cloudinary.com/dmgfep69f/image/upload/v1640536316/orgeial7kefv2dzsdqqt.webp",
      public_id: 0,
    });
  };

  const resetParrafo = () => {
    setParrafoActivo(false);
    setParrafo("");
  };

  const onParrafoSubmit = async () => {
    if (parrafosprodcuto.length) {
      try {
        socket.emit("subirparrafonuevo", {
          Parrafo,
          pid: state.pid,
        });
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se Añadio el Parrafo con Exito",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Sucedio un Error Al Subir El Parrafor",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      resetParrafo();
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "No se Aceptan Mas de 3 Parrafos",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    setParrafoActivo(false);
    setParrafo("");
  };

  const guardarcarrito = () => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: "success",
      title: "producto agregado al Carrito",
    });
    dispatch(añadirCarrito(state));
  };

  const shareButton = () =>{
    if(navigator.share){
      navigator.share({
        title: state.titulo,
        text: parrafosprodcuto[0],
        url: match.url,
      })
      .then(()=>console.log("compartido"))
      .catch((error)=>{
        console.log(error);
      })
    }
  }

  return (
    <>
      {carga ? (
        <CircularProgress />
      ) : (
        <>
          <Helmet>
            <title>FerreAgros - {state.titulo}</title>
            <meta name="description" content={parrafosprodcuto[0]} />
          </Helmet>
          <div className="inicioPage">
            <div className="gridproducto">
              <input
                type="file"
                id="fileperfilmas"
                aria-label="File browser example"
                onChange={onFilesavefoto}
              ></input>
              <div>
                <div className="fleximg">
                  <div className="cajafotoproducto">
                    {Foto?.secure_url ? (
                      <img
                        id="fotoOver"
                        className="fotoproducto"
                        src={Foto.secure_url}
                        alt="producto"
                      />
                    ) : (
                      <img
                        id="fotoOver"
                        className="fotoproducto"
                        src="https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg"
                        alt="producto"
                      />
                    )}
                  </div>
                  <div className="morefotos">
                    {fotos.map((foto, i) => (
                      <CajaProductoFotos
                        key={i}
                        url={foto}
                        func={onOverFoto}
                        pid={state.pid}
                        state={state}
                      />
                    ))}

                    {miuid.rol === "Administrador" ||
                    miuid.rol === "Empleado" ? (
                      <i
                        className="bx bxs-plus-square"
                        onClick={onFilefoto}
                      ></i>
                    ) : null}
                  </div>
                  <div className="flexrow">
                    {(miuid.rol === "Administrador" ||
                      miuid.rol === "Empleado") &&
                    Parrafoactivo === false ? (
                      <button
                        className="buttonagregarparrafo"
                        type="button"
                        onClick={agregarparrafo}
                      >
                        Agregar Parrafo <i className="bx bx-plus-medical"></i>
                      </button>
                    ) : null}
                  </div>
                  {Parrafoactivo === true ? (
                    <textarea
                      maxLength={600}
                      value={Parrafo}
                      className="textareaparrafo"
                      onChange={onChangeMensaje}
                    ></textarea>
                  ) : null}
                  <div className="centerboton">
                    {Parrafoactivo === true ? (
                      <button
                        type="button"
                        className="botonguardarperfil"
                        onClick={onParrafoSubmit}
                      >
                        Guardar
                      </button>
                    ) : null}
                    {Parrafoactivo === true ? (
                      <button
                        type="button"
                        className="botonguardarperfil"
                        onClick={resetParrafo}
                      >
                        Cancelar
                      </button>
                    ) : null}
                  </div>
                  <div className="centerboton">
                    {agregarfotos.public_id !== 0 ? (
                      <button
                        type="button"
                        className="botonguardarperfil"
                        onClick={onSubmit}
                      >
                        Guardar
                      </button>
                    ) : null}
                    {agregarfotos.public_id !== 0 ? (
                      <button
                        type="button"
                        className="botonguardarperfil"
                        onClick={reset}
                      >
                        Cancelar
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              <div>
                <div className="infocom">
                  <h3 className="infocom-title mb-4">{state.titulo}</h3>
                  <hr
                    class="solid-divider mb-4 bg-dark"
                    style={{ borderTop: "solid 1px black", opacity: "1" }}
                  />
                  <ul className="infocom-title-list">
                    <li className="infocom-title-list-item">
                      {parrafosprodcuto[0]?.slice(0, 600)}
                    </li>
                  </ul>
                  <div className="d-flex justify-content-between align-items-center  mb-4">
                    <Typography
                      variant="h4"
                      gutterBottom
                      component="h4"
                      fontWeight={700}
                      className="infocom-price-first"
                    >
                      $
                      {new Intl.NumberFormat().format(state.detalles[0].Precio)}
                    </Typography>
                    
                    {state.detalles[0].PrecioD != "0"?<Typography
                      variant="h4"
                      gutterBottom
                      component="h4"
                      fontWeight={700}
                      className="infocom-price-second"
                    >
                      $
                      {new Intl.NumberFormat().format(
                        state.detalles[0].PrecioD
                      )}
                    </Typography>:null}
                  </div>
                  <hr
                    class="solid-divider mb-4 bg-dark"
                    style={{ borderTop: "solid 1px black", opacity: "1" }}
                  />
                  <Typography
                    variant="body1"
                    gutterBottom
                    component="p"
                    className="infocom-tags-list mb-3"
                  >
                    <strong className="infocom-tags"> Tags: </strong>
                    {state.detalles[0].Familia +
                      "    " +
                      state.detalles[0].Categoria}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    component="p"
                    className="infocom-tags-list mb-3"
                  >
                    <strong className="infocom-tags"> Categoria: </strong>
                    {state.detalles[0].Familia}
                  </Typography>
                  <button
                    className="infocom-carrito mt-5"
                    onClick={guardarcarrito}
                    style={{ cursor: "pointer" }}
                  >
                    <ShoppingCartCheckoutIcon className="me-3" />
                    Añadir a carrito
                  </button>
                  <ul style={{ listStyle: "none" }} className="p-0 mt-5">
                    <li>
                      <button style={{cursor:"pointer",backgroundColor:"white",border:"none"}} onClick={shareButton}>
                        <AiOutlineShareAlt fontSize={20} className="me-3" />
                        Compartir
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="product-description">
            <h3 className="product-description-title">Descripción</h3>
            <ul className="product-description-list mb-5">
              {parrafosprodcuto.map((product, index) => (
                <ParrafosProducto
                  key={index}
                  parrafo={product}
                  state={state}
                  miuid={miuid}
                  index={index}
                  socket={socket}
                />
              ))}
            </ul>
            <h1 className="product-description-subTitle">
              Productos relacionados
            </h1>
            <div className="slider-producto">
            <Slider {...settings} adaptiveHeight={true} centerPadding="90px">
              {categoriaencontradas?.map((produc, i) => (
                <Cards
                key={i}
                  history={history}
                  product={produc}
                  image={
                    produc.fotosdescripsion[0]?.secure_url
                      ? produc.fotosdescripsion[0].secure_url
                      : "https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg"
                  }
                  mobile={produc.titulo}
                  actual_price={produc.detalles[0].Precio}
                  domicilio={produc.detalles[0].DomicilioIncluido}
                />
              ))}
            </Slider>
                </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default Producto;
