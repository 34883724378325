import { types } from '../types/chat';

 const initialState = {
     uid: '',
     chatActivo: null, 
     usuarios: [], 
     mensajes: [],
 }

 function Reducer ( state= initialState, action ){


    switch ( action.type ) {
        case types.chatsolicitud:
            return {
                ...state,
                uid: [ ...action.payload ]
            }
        case types.usuariosCargados:
            return {
                ...state,
                usuarios: [ ...action.payload ]
            }
        
        case types.añadirUsuario:
                return {
                    ...state,
                    usuarios: [action.payload,...state.usuarios],
                }

        case types.eliminarUsuario:
            return {
                ...state,
                usuarios: state.usuarios.filter(function(usuario){
                    return action.payload !== usuario.uid;
                  })
                }
        case types.modificarusuario:
            return {
                ...state,
                usuarios: state.usuarios.map(function(usuario){
                     if(action.payload.uid !== usuario.uid){
                      return usuario;
                     }else{
                      return action.payload;
                     }
           })
          }
        case types.nuevoMensaje:
            if ( state.chatActivo?.iduser === action.payload.de || state.chatActivo?.iduser === action.payload.para ) {
                return {
                    ...state,
                    mensajes: [ ...state.mensajes, action.payload ]
                }
            } else {
                return state;
            }

        case types.cargarMensajes:
            return {
                ...state,
                mensajes: [ ...action.payload ]
            }
            case types.regeneratechat:
                return initialState;
        case types.exitChat:
            return {
                uid: '',
                chatActivo: null, 
                usuarios: [...state.usuarios], 
                mensajes: [],
            };
        
        default:
            return state;
    }

}
export default Reducer;