import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Inicio.scss";
import "./contactanos.scss";
import React, { useCallback, useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "./Footer";
import { fetchstoken } from "../helpers/fetchmetod";
import { AiOutlineShoppingCart, AiOutlineHeart } from "react-icons/ai";
import { GoWatch } from "react-icons/go";
import { MdOutlineLocationOn } from "react-icons/md";
import CircularProgress from "./CircularProgress";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import cruceFacebook from "./images/cruce-de-tandilia-2.jpg";
import Banner from "./images/home.png";
import CuadroVerder from "./images/cuadrado.png";
import PuntosBlack from "./images/PuntosBlack.png";
import PuntosGreen from "./images/PuntosGreen.png";
import { BiSend , BiPhone} from "react-icons/bi";
import useMediaQuery from '@mui/material/useMediaQuery';
import Contactanos from "./images/contacanos.png";
import TextField from "@mui/material/TextField";
import IconMoney from "./images/icon-money.png";
import Typography from "@mui/material/Typography";
import { UploadPhoto } from "../helpers/cloudinaryUpload";
import { SocketContext } from "../redux/context/contextchat";
import { agregarInformacion } from "../redux/actions/auth";
import { styled } from "@mui/material/styles";
import { añadirCarrito } from "../redux/actions/productos";
//useEffect para poder hacer responsive la carta
const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#3D9A67',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#3D9A67',
    },
    '&:hover fieldset': {
      borderColor: '#3D9A67',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#3D9A67',
    },
  },
});

function Inicio({ history }) {
  const [carga, setCarga] = useState(true);
  const [Width, setWidth] = useState(window.innerWidth);
  const [productos, setState] = useState([]);
  const mediaQuery = useMediaQuery('(max-width:700px)');
  const mediaQueryCelular = useMediaQuery('(max-width:500px)');
  const [Image, setImage] = useState()
  const [productoPrincipal, setProductPrincipal] = useState({});
  const dispatch = useDispatch();
  const rol = useSelector((yo) => yo.infoUsuario.rol);
  const banner = useSelector((yo) => yo.infoUsuario.fotos);
  const { socket } = useContext(SocketContext);
  const [correo, setCorreo] = useState({
    contact_user: "",
    correo_user: "",
    asunto_user: "",
    descripcion_user: "",
    telefono: "",
  });

  const guardarcarrito = (event,product) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: "success",
      title: "producto agregado al Carrito",
    });
    dispatch(añadirCarrito(product));
    event.stopPropagation();
  };

  const cambiarTamaño = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", cambiarTamaño);
    return () => {
      window.removeEventListener("resize", cambiarTamaño);
    };
  });

  const onChangeCorreo = (e) => {
    const { name, value } = e.target;
    setCorreo({
      ...correo,
      [name]: value,
    });
  };

  const cargarProductos = useCallback(async () => {
    const productoPrincipal = await fetchstoken("crearproductoprincipal");
    const categoriasTodas = await fetchstoken(`mostrar`);
    const destacados = await fetchstoken(`mostrar/destacados`);
    const descuentos = await fetchstoken(`mostrar/descuentos`);
    if (descuentos.ok && destacados.ok && categoriasTodas.ok && productoPrincipal.ok) {
      dispatch(agregarInformacion(categoriasTodas.fotos));
      setProductPrincipal(productoPrincipal.data)
      setState({
        categoriasTodas: categoriasTodas.filtervar,
        Destacados: destacados.filtervar,
        des: descuentos.filtervar,
      });
      setImage(descuentos.filtervar[2].fotosdescripsion[0].secure_url);
      setCarga(false);
      return true;
    } else {
      return false;
    }
  }, [setState]);

  useEffect(() => {
    cargarProductos();
  }, [cargarProductos]);

  const correoEmail = async (e) => {
    e.preventDefault();
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    try {
      const resultCorreo = await fetchstoken("correo", correo, "POST");
      if (!resultCorreo.ok) {
        throw Error(resultCorreo.errors.msg);
      }
      Toast.fire({
        icon: "success",
        title: "el correo se envio con exito",
      });
      setCorreo({
        contact_user: "",
        correo_user: "",
        asunto_user: "",
        descripcion_user: "",
        telefono: "",
      });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  const onFilefoto = () => {
    document.querySelector("#fileperfilmas").click();
  };

  const onFilesavefoto = async (e) => {
    const file = e.target.files[0];
    try {
      const url = await UploadPhoto(file);
      socket.emit("fotoBanner", {
        url,
        idFoto: banner[0].idBanner,
      });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Foto Subida",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (err) {
      console.log(err);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Sucedio un Error Al Subir La Foto",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      Width > 1348
        ? 5
        : Width > 1057
        ? 4
        : Width > 740
        ? 3
        : Width > 500
        ? 2
        : 1,
    slidesToScroll:
      Width > 1348
        ? 5
        : Width > 1057
        ? 4
        : Width > 740
        ? 3
        : Width > 500
        ? 2
        : 1,
  };

  const redirect = (data) => {
    history.push(`/categorias/${data}`);
  };
  return (
    <>
      {carga ? (
        <CircularProgress />
      ) : (
        <>
          <Helmet>
            <link
              rel="shortcut icon"
              href="https://cdn-icons-png.flaticon.com/512/6183/6183559.png"
            />
            <title>Todo sobre productos - FerreAgros</title>
            <meta
              name="description"
              content="encontraras gran variedad de productos relacionados a la agronomia y al campo a los mejores precios competitivos en el mercado colombiano"
            />
            <meta property="og:title" content="FerreAgrosColombia - Inicio" />
            <meta
              property="og:description"
              content="Conoce todo sobre nuestros productos y servicios con lo relacionado a la industrial agricola."
            />
            <meta property="og:image" content={cruceFacebook} />
          </Helmet>
          <section className={`banner d-flex ${!mediaQueryCelular?"":"pe-5"} ps-5 justify-content-between`}>
            <div className={`${!mediaQueryCelular?"w-50":""} d-flex flex-column justify-content-center`}>
              <span
                className="poppins mb-5"
                style={{
                  fontSize: "16px",
                  color: "#3D9A67",
                  border: "1px solid #3D9A67",
                  width: "146px",
                  textAlign: "center",
                  borderRadius: "15px",
                }}
              >
                Producto nuevo
              </span>
              <h1
                className="title-home"
                style={{ fontWeight: 900 }}
              >
                {productoPrincipal.titulo}
              </h1>
              <p
                className="poppins"
                style={{
                  marginTop: "16px",
                  fontSize: "24px",
                  maxWidth: "600px",
                  textAlign: mediaQueryCelular?"center":"start"
                }}
              >
                {productoPrincipal?.textdescripsion[0]}
              </p>
              <div className="mt-3 d-flex flex-wrap m-3">
                <button className="buttons-home poppins green me-3 mb-3" onClick={()=>history.push(`/producto/${productoPrincipal.titulo.replace(/ /g, "-").toLowerCase()}`)}>
                  VER
                </button>
                <button className="buttons-home poppins mb-3" onClick={e=>guardarcarrito(e,productoPrincipal)}>AGREGAR AL CARRITO</button>
              </div>
            </div>

            {!mediaQueryCelular?<img
              src={CuadroVerder}
              style={{ position: "absolute", right: 0, width: "34%" }}
            ></img>:null}
            {!mediaQuery?<>
            <img
              src={PuntosBlack}
              style={{
                position: "absolute",
                right: 300,
                top: 150,
                width: "100px",
              }}
            ></img>
            <img
              src={PuntosGreen}
              style={{
                position: "absolute",
                left: "50%",
                bottom: "10%",
                width: "120px",
              }}
            ></img></>:null}

            {!mediaQueryCelular?<div className="w-50 position-relative">
              <img
                src={productoPrincipal?.fotosdescripsion[0]?.secure_url}
                style={{
                  padding: "100px 0 100px 100px",
                  width: "100%",
                  position: "relative",
                }}
                className="img-carrito-inicio"
              ></img>
              <div className="card-promotion">
                <p className="card-promotion-p1">{100 -
            parseInt(
              (productoPrincipal?.detalles[0]?.Precio / productoPrincipal?.detalles[0]?.PrecioD) * 100
            )}%</p>
                <p className="card-promotion-p2">Descuento</p>
                <img src={IconMoney} className="icon"></img>
              </div>
            </div>:null}

            <input
              type="file"
              id="fileperfilmas"
              aria-label="File browser example"
              onChange={onFilesavefoto}
              style={{ display: "none" }}
            ></input>
          </section>
          <div className="bannerDetailHome">
            <h1 className="bannerDetailHome-title poppins">
              Nuestros productos
            </h1>
            <p className="bannerDetailHome-desc poppins">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className="bannerDetailHomePart2">
              <div className="bannerDetailHomePart2-contenedor">
                <h1 className="bannerDetailHome-title2 poppins">
                  Nombre del Producto
                </h1>
                <p className="bannerDetailHome-desc poppins">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </div>

          <div className="contendioinicio">
            <div className="categoriasProductos">
              {productos.categoriasTodas?.map((categoria) => (
                <div
                  onClick={() => redirect(categoria.categoria)}
                  key={categoria.uidfoto}
                  className="categoriacajaproducto"
                  style={{ cursor: "pointer" }}
                >
                  <img src={categoria.urlfoto} alt="imageCategoria" />
                </div>
              ))}
            </div>

            <div className="margininiciotop position-relative">
              <div className="margininiciotop-arriba">
                <Typography
                  variant="h4"
                  gutterBottom
                  className="loMasVendido"
                  component="h4"
                  fontWeight={600}
                >
                  Lo más vendido
                </Typography>
                <div
                  className="m-auto d-flex flex-row justify-content-between"
                  style={{ zIndex: 3,flexWrap:"wrap",paddingLeft:mediaQuery?"20px":0 }}
                >
                  {productos.Destacados?.filter((_, i) => i <= 5).map(
                    (produc, i) => (
                      <img
                        key={i}
                        src={produc.fotosdescripsion[0].secure_url}
                        style={{
                          width: mediaQuery?"25%" :"calc(14% - 20px)",
                          marginRight:mediaQuery?"20px":"0",
                          marginBottom:mediaQuery?"20px":"0",
                          zIndex: 5,
                          cursor:"pointer"
                        }}
                        onClick={()=>history.push(`/producto/${produc.titulo.replace(/ /g, "-").toLowerCase()}`)}
                      />
                    )
                  )}
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "90%", margin: "auto",flexDirection:mediaQuery?"column":"row" }}
                >
                  {productos.des
                    ?.filter((_, i) => i <= 1)
                    .map((produc, i) => {
                      return (
                        <div className="margininiciotop-arriba-containers">
                          <div className="margininiciotop-arriba-containers-childrens">
                            <h3 className="margininiciotop-arriba-containers-childrens-title text-uppercase">
                              {produc.titulo}
                            </h3>
                            <p className="margininiciotop-arriba-containers-childrens-parrafo">
                              {produc.textdescripsion[0]?.slice(0,200)}
                            </p>
                            <button className="margininiciotop-arriba-containers-childrens-button" onClick={()=>history.push(`/producto/${produc.titulo.replace(/ /g, "-").toLowerCase()}`)}>
                              Ver
                            </button>
                          </div>
                          <div className="margininiciotop-arriba-containers-imgContainer">
                            <img
                              className="margininiciotop-arriba-containers-imgContainer-img"
                              src={produc.fotosdescripsion[0].secure_url}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {productos.des
                  ?.filter((_, i) => i === 2)
                  .map((produc, i) => (
                    <div className="d-flex justify-content-between margininiciotop-arriba-container-three">
                      <div className="margininiciotop-arriba-container-three-imgContainer">
                        <img
                          className="pointer margininiciotop-arriba-container-three-imgContainer-img"
                          src={Image}
                          onClick={()=>history.push(`/producto/${produc.titulo.replace(/ /g, "-").toLowerCase()}`)}
                        />
                      </div>
                      <div className="margininiciotop-arriba-container-three-imgsContainers d-flex justify-content-between flex-column">
                        {produc.fotosdescripsion.map((img) => (
                          <img
                            className="margininiciotop-arriba-container-three-imgsContainers-img"
                            src={img.secure_url}
                            onMouseOver={()=>setImage(img.secure_url)}
                          />
                        ))}
                        
                      </div>
                      <div className="margininiciotop-arriba-container-three-Container d-flex flex-column p-3">
                        <h3 className="pointer margininiciotop-arriba-container-three-Container-title" onClick={()=>history.push(`/producto/${produc.titulo.replace(/ /g, "-").toLowerCase()}`)}>
                          {produc.titulo}
                        </h3>
                          <p className="margininiciotop-arriba-container-three-Container-parrafo">
                            {produc.textdescripsion[0]?.slice(0,200)}
                          </p>

                        <hr class="solid-divider mb-4" />

                        <div className="d-flex justify-content-between flex-wrap">
                          <h4 className="margininiciotop-arriba-container-three-Container-price">
                            ${" "}
                            {new Intl.NumberFormat().format(
                              parseInt(produc.detalles[0].Precio)
                            )}
                          </h4>
                          <div className="d-flex margininiciotop-arriba-container-three-Container-check">
                            <p className="margininiciotop-arriba-container-three-Container-check-text">
                              en stock
                            </p>
                            <input
                              className="margininiciotop-arriba-container-three-Container-check-check"
                              type="checkbox"
                              checked={true}
                            />
                          </div>
                        </div>

                        <div className="d-flex mt-3 flex-wrap">
                          <button className="margininiciotop-arriba-container-three-Container-button me-4" onClick={e=>guardarcarrito(e,produc)}>
                            Añadir a carrito
                          </button>
                          <div className="margininiciotop-arriba-container-three-Container-subButton d-flex flex-column me-4 justify-content-center" onClick={e=>guardarcarrito(e,produc)}>
                            <AiOutlineShoppingCart fontSize="26px" />
                          </div>
                          <div className="margininiciotop-arriba-container-three-Container-subButton d-flex flex-column justify-content-center">
                            <AiOutlineHeart fontSize="26px" />
                          </div>
                        </div>
                        <hr class="solid-divider mt-4" />

                        <p className="mt-4 margininiciotop-arriba-container-three-Container-parrafo fs-6">
                          <strong>Categorias: </strong>
                          <span>{produc.detalles[0].Categoria}</span>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          <div className="contactanos-container-background">                              
            <div className="d-flex flex-row px-5 py-5 contactanos-container">
              <div className="contactanos-container-first">
                <h3 className="contactanos-container-first-title">
                  Contactanos
                </h3>
                <p className="contactanos-container-first-description mb-5">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
                <form className="d-flex flex-column" onSubmit={correoEmail}>
                  <div className="d-flex flex-row mb-5">
                    <CssTextField
                      label="Nombre"
                      variant="standard"
                      focused className="me-3 w-50"
                      name="contact_user"
                      value={correo.contact_user} 
                      onChange={onChangeCorreo}
                    />
                    <CssTextField
                      label="Correo"
                      variant="standard"
                      focused className="w-50"
                      name="correo_user" 
                      value={correo.correo_user} 
                      onChange={onChangeCorreo}
                    />
                  </div>
                  <div className="d-flex flex-row mb-5">
                    <CssTextField
                      label="Telefono"
                      variant="standard"
                      name="telefono" 
                      value={correo.telefono} 
                      onChange={onChangeCorreo}
                      focused className="pe-3 w-50"
                    />
                    <CssTextField
                      label="Asunto"
                      variant="standard"
                      focused 
                      className="w-50"
                      value={correo.asunto_user} 
                      onChange={onChangeCorreo}
                      name="asunto_user"
                    />
                  </div>
                  <div className="d-flex flex-row mb-5">
                    <CssTextField
                      label="Descripcion"
                      variant="standard"
                      focused className="w-100"
                      name="descripcion_user"
                      value={correo.descripcion_user} 
                      onChange={onChangeCorreo}
                    />
                  </div>
                  <div className="d-flex flex-row justify-content-between mb-5 flex-wrap">
                    <div className="d-flex flex-row align-items-center mb-3">
                      <input type="checkbox" className="contactanos-container-first-checkbox" style={{width:"25px",height:"25px"}}/>
                      <p className="contactanos-container-first-description-checkbox">Recibir notificaciones de parte de FerreAgros</p>
                    </div>
                    <button className="contactanos-container-first-button" type="submit"><BiSend style={{marginRight:"10px"}} /> Enviar</button>
                  </div>
                  <div className="d-flex flex-row justify-content-between flex-wrap">
                    <div className="d-flex flex-row">
                      <p className="contactanos-container-first-elements"><BiPhone className="me-2" fontSize={19}/> (+57) 3166229671</p>
                    </div>
                    <div className="d-flex flex-row">
                      <p className="contactanos-container-first-elements"><MdOutlineLocationOn className="me-2" fontSize={19}/> Cartago valle, Cra 7 #10-83</p>
                    </div>
                    <div className="d-flex flex-row">
                      <p className="contactanos-container-first-elements"><GoWatch className="me-2" fontSize={19}/> Lun a Vie - 8 A.M - 6 P.M</p>
                    </div>
                  </div>
                </form>
              </div>
              <div className="contactanos-container-second">
                <img
                  className="contactanos-container-second-image"
                  src={Contactanos}
                />
              </div>
            </div>
          </div>
            {/* <div className="fondoVerdeContacto">
              <div className="ContentInfor">
                <div className="informacionContacto">
                  <span>
                    <i className="bx bx-phone"></i>Llamanos:{" "}
                  </span>
                  <p>+57 3166229671 </p>
                  <span>
                    <i className="bx bx-current-location"></i>Ubicacion:{" "}
                  </span>
                  <p>Cartago valle, Cra 7 #10-83</p>
                  <span>
                    <i className="bx bx-briefcase-alt-2"></i>Horarios de
                    Trabajo:{" "}
                  </span>
                  <p>Lunes a Viernes, 8 A.M - 6 P.M</p>
                </div>

                <form name="formulario-contacto" onSubmit={correoEmail}>
                  <label name="nombre">Nombre:</label>
                  <input
                    type="text"
                    name="contact_user"
                    placeholder="Ingresa tu Nombre"
                    value={correo.contact_user}
                    onChange={onChangeCorreo}
                  />
                  <label>Correo Electronico:</label>
                  <input
                    type="email"
                    name="correo_user"
                    placeholder="Ingresa tu Correo"
                    value={correo.correo_user}
                    onChange={onChangeCorreo}
                  />
                  <label>Asunto:</label>
                  <input
                    type="text"
                    name="asunto_user"
                    placeholder="Asunto"
                    value={correo.asunto_user}
                    onChange={onChangeCorreo}
                  />
                  <label>Telefono:</label>
                  <input
                    type="text"
                    name="telefono"
                    placeholder="Telefono"
                    value={correo.telefono}
                    onChange={onChangeCorreo}
                  />
                  <label>Descripcion:</label>
                  <textarea
                    name="descripcion_user"
                    placeholder="Descripcion"
                    value={correo.descripcion_user}
                    onChange={onChangeCorreo}
                  />
                  <button type="submit">Enviar</button>
                </form>
              </div>
            </div> */}
          <div className="background-ubicacion">
            <h2 className="bannerDetailHome-title poppins mb-3" style={{color:"#3D9A67"}}>
            Ubicación satelital
            </h2>
            <p className="background-ubicacion-descripcion mb-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div className="mapaGps">
              <iframe
                title="googlemaps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.1323420752647!2d-75.91419058466536!3d4.747043542574395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e38701d57b5d43f%3A0x830b553290afbcf5!2sCra.%207%20%2310-83%2C%20Cartago%2C%20Valle%20del%20Cauca!5e0!3m2!1ses!2sco!4v1647957807826!5m2!1ses!2sco"
                width="600"
                height="450"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
}

export default Inicio;
