import React, { useState, useContext } from "react";
import { SocketContext } from "../redux/context/contextchat";
import Swal from "sweetalert2";
import { UploadPhoto } from "../helpers/cloudinaryUpload";

const CajaCategoriaSubidas = ({ Producto, history, rol, ruta, Categorias }) => {
  const { socket } = useContext(SocketContext);
  const initialstate = Producto;
  const [state, setState] = useState(initialstate);
  const [Modificar, setModificar] = useState(false);
  const [urlmas, setUrl] = useState(Producto);
  const eliminarproducto = () => {
    if (rol === "Administrador" || rol === "Empleado") {
      Swal.fire({
        title: "estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "¡Sí, bórralo!",
      }).then((result) => {
        if (result.isConfirmed) {
          socket.emit("categoriaeliminar", {
            uidfoto: Producto.uidfoto,
            Producto,
          });

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "categoria eliminada con exito",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    } else {
      return Swal.fire({
        position: "top-end",
        icon: "error",
        title: "No tienes Permiso",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const redirect = () => {
    history.push(`/categorias/${Producto.categoria}`);
  };

  const botonmodificar = () => {
    if (rol === "Administrador" || rol === "Empleado") {
      setModificar(!Modificar);
      if (false === Modificar) {
        setState(initialstate);
        setUrl(Producto);
      }
    } else {
      return Swal.fire({
        position: "top-end",
        icon: "error",
        title: "No tienes Permiso",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const guardar = async () => {
    if (Modificar === true) {
      let url = null;
      if (urlmas.uidfoto === initialstate.uidfoto || urlmas.uidfoto === 0) {
      } else {
        url = await UploadPhoto(urlmas);
        setUrl({
          urlfoto: url.secure_url,
          uidfoto: url.public_id,
        });
      }
      socket.emit("categoriamodificar", {
        Producto: state,
        url,
      });

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: "Informacion Guardada",
      });

      setModificar(!Modificar);
    }
  };
  const onChangeMensaje = (e) => {
    const { name, value } = e.target;
    if (name === "mostrar") {
      setState({
        ...state,
        mostrar: e.target.checked,
      });
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  const onFile = () => {
    Swal.fire({
      title: "Quieres cambiar la foto?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, Editar!",
    }).then((result) => {
      if (result.isConfirmed) {
        document.querySelector(`#fileproductoeditar${Producto.cid}`).click();
      }
    });
  };
  const onFilesave = async (e) => {
    const file = e.target.files[0];
    setUrl(file);
  };
  return (
    <>
      <tr>
        <td scope="row" className="title">
          {ruta === "familia" ? (
            Producto.urlfoto ? (
              <img
                alt={`imagenproductomodificado${Producto.cid}`}
                className="hover"
                src={Producto.urlfoto}
                onClick={Modificar === true ? onFile : redirect}
              ></img>
            ) : (
              <img
                alt={`imagenproductomodificado${Producto.cid}`}
                className="hover"
                src="https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg"
                onClick={Modificar === true ? onFile : redirect}
              ></img>
            )
          ) : (
            <>
              {Modificar === false ? Producto.hijo : null}
              {Modificar === true ? (
                <select
                  name="hijo"
                  onChange={onChangeMensaje}
                  value={state.hijo}
                >
                  {Categorias.map((categoria) => (
                    <option>{categoria}</option>
                  ))}
                </select>
              ) : null}
            </>
          )}
        </td>
        {Modificar === false ? <td>{Producto.categoria}</td> : null}
        {Modificar === true ? (
          <td>
            <input
              className="tituloUsuario"
              name="categoria"
              type="text"
              value={state.categoria}
              maxLength={100}
              onChange={onChangeMensaje}
            />
          </td>
        ) : null}

        {!(ruta === "familia") ? (
          <td>siempre</td>
        ) : (
          <>
            {Modificar === false ? (
              <td>
                {Producto.mostrar === "true" || state.mostrar === true
                  ? "si"
                  : "no"}
              </td>
            ) : null}
            {Modificar === true ? (
              <td>
                <input
                  className="tituloUsuario"
                  name="mostrar"
                  type="checkbox"
                  checked={
                    state.mostrar === "true" || state.mostrar === true
                      ? true
                      : false
                  }
                  onChange={onChangeMensaje}
                />
              </td>
            ) : null}
          </>
        )}
        {Modificar === false ? (
          <td>
            <button
              onClick={botonmodificar}
              type="button"
              className="botonproductoagregar add "
            >
              {" "}
              <i className="bx bx-pencil"></i>
            </button>
            <button
              type="button"
              onClick={eliminarproducto}
              className="botonproductoagregar delete"
            >
              <i className="bx bxs-trash-alt"></i>
            </button>
          </td>
        ) : null}
        {Modificar === true ? (
          <td>
            <button
              onClick={guardar}
              type="button"
              className="botonproductoagregar add "
            >
              Guardar
            </button>
            <button
              onClick={botonmodificar}
              type="button"
              className="botonproductoagregar delete"
            >
              {" "}
              cancelar
            </button>
          </td>
        ) : null}
        <input
          type="file"
          id={`fileproductoeditar${Producto.cid}`}
          className="fileproductoeditar"
          aria-label="File browser example"
          onChange={onFilesave}
        ></input>
      </tr>
    </>
  );
};
export default CajaCategoriaSubidas;
