import { fetchCToken } from '../helpers/fetchmetod';
import { user } from "../redux/actions/chat";
import Swal from 'sweetalert2'
import { SocketContext } from '../redux/context/contextchat'
import { useSelector,useDispatch} from 'react-redux';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { teal } from '@mui/material/colors';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import PublicIcon from '@mui/icons-material/Public';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CajaUsuariosubidosMUI from './CajaUsuariosubidosMUI';
import { VscDiffAdded, VscError } from "react-icons/vsc";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function UsuariosMUI({history}) {
  
const dispatch = useDispatch();
const productos =  useSelector(usuarios => usuarios.chat.usuarios);
const uid =  useSelector(usuarios => usuarios.infoUsuario.uid);
const {socket} = React.useContext(SocketContext);
const [NewProducto, setNewProducto] = React.useState({
    nombre:'',
    correo:'',
    password:'',
    rol: 'Empleado',
});

const obtenerproductos = React.useCallback(
  async() => {
    const ordenes = await fetchCToken('Usuarios');
    if(!ordenes.ok){
    return ;
    }
    dispatch(user(ordenes.producto))
  }, [dispatch],
)
React.useEffect( ()=>{
  obtenerproductos()
 },[obtenerproductos])

const [agregar, setagregar] = React.useState(false);

const onChangeMensaje = (e) => {
    const { name, value } = e.target;
    setNewProducto({
      ...NewProducto,
      [name]: value,
    });
  };

  const cambiar = () =>{
      setagregar(!agregar);
      setNewProducto({
        nombre:'',
        correo:'',
        password:'',
        rol: 'Empleado',
    })
    
  }


  const onSubmit = async(e) => {
    e.preventDefault();
    if(NewProducto.nombre.length>0 && NewProducto.correo.length>0){
  try{
    socket.emit('newusuario',{
      producto: NewProducto
      })
setagregar(!agregar);
Swal.fire({
  position: 'top-end',
  icon: 'success',
  title: 'Foto Actualizada',
  showConfirmButton: false,
  timer: 1500
})
}catch(err){
  console.log(err)
  Swal.fire({
    position: 'top-end',
    icon: 'error',
    title: 'Sucedio un Error',
    showConfirmButton: false,
    timer: 1500
  })
}

}else{
  if(NewProducto.Precio < 20000){
    return Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'El precio debe ser de minimo $20.000',
      showConfirmButton: false,
      timer: 1500
    })
  }
  Swal.fire({
    position: 'top-end',
    icon: 'error',
    title: 'Llenar Titulo y Descripsion',
    showConfirmButton: false,
    timer: 1500
  })
}
  };
  return (<>
  <div className="header-page">
              <h1 className="header-page-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
              <p className="header-page-parrafo">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
    <TableContainer component={Paper} sx={{width:"80%", margin:"auto",mt:"40px",boxShadow:"inherit",overflowX:"auto"}} className="d-flex flex-column mb-5" >
      <button onClick={cambiar} style={{margin:"auto",marginBottom:"30px",color:"white",border:"none",padding:"9px 26px" ,backgroundColor:"#3D9A67",":hover":{opacity:"0.5",background:"#3D9A67"}}}> 
      {agregar? <VscError fontSize='17px' className='me-2'/> :<VscDiffAdded fontSize='17px' className='me-2'/> } {agregar? "Cancelar": "Agregar Usuario" } 
      </button>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead   sx={{background:"#4C4A4A"}}>
          <TableRow>
            <StyledTableCell align='center'> Nombre</StyledTableCell>
            <StyledTableCell align="center">Correo</StyledTableCell>
            <StyledTableCell align="center">Password</StyledTableCell>
            <StyledTableCell align="center">Rol</StyledTableCell>
            <StyledTableCell align="center">Acciones</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {(agregar === true)?
        <StyledTableRow>
          <StyledTableCell align="center" component="th" scope="row"><TextField id="standard-basic" label="Nombre" variant="standard" name='nombre' onChange={onChangeMensaje}/></StyledTableCell>
          <StyledTableCell align="center" component="th" scope="row"><TextField type="email" id="standard-basic" label="Correo" variant="standard" name='correo' onChange={onChangeMensaje}/></StyledTableCell>
          <StyledTableCell align="center" component="th" scope="row"><TextField type="password" id="standard-basic" label="Password" variant="standard" name='password' onChange={onChangeMensaje}/></StyledTableCell>
          <StyledTableCell align="center" component="th" scope="row"> 
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Rol</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={NewProducto.rol}
          onChange={onChangeMensaje}
          label="Rol"
          name='rol'
        >
          <MenuItem value='Administrador'>ADMIN</MenuItem>
          <MenuItem value="Empleado">Empleado</MenuItem>
          <MenuItem value="Observador">Observador</MenuItem>
        </Select>
      </FormControl></StyledTableCell>
          <StyledTableCell align="center">
                <Button variant="contained" startIcon={<PublicIcon /> } sx={{mb:"10px", backgroundColor:teal[900],":hover":{backgroundColor: teal[700]}}} onClick={onSubmit}>Publicar</Button>
          </StyledTableCell>
        </StyledTableRow>:null
      }
          {productos.map((producto) => {
            if(uid === producto.uid){
              return null;
            }else{
              return <CajaUsuariosubidosMUI  key={producto.pid} Producto={producto} history={history}/>
            }
          }
           
          )}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}