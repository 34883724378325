export const types = {
    usuariosCargados: '[Chat] Usuarios cargados',
    activarChat: '[Chat] Activar Chat',
    nuevoMensaje: '[Chat] Nuevo Mensaje',
    cargarMensajes: '[Chat] Cargar Mensajes',
    regeneratechat : '[Chat] regeneratechat',
    chatsolicitud : '[Chat] chatsolicitud',
    exitChat : '[Chat] exitChat',
    añadirUsuario : '[Chat] añadirUsuario',
    eliminarUsuario : '[Chat] eliminarUsuario',
    modificarusuario : '[Chat] modificarusuario'

}