import React,{useState} from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { teal } from '@mui/material/colors';
import { fetchstoken } from '../helpers/fetchmetod';
import { loginstate } from '../redux/actions/auth';
import { useSocket } from "../SocketsConnection/useSocket";
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
export default function IniciarSesionMUI() {
  const dispatch = useDispatch();
  const { conectarSocket } = useSocket('https://round-motley-chili.glitch.me/');
  const [login, setLogin] = useState({
   correo:'',
   password: ''
});
const loginsesion= ()=>{
  return (login.correo.length > 0 && login.password.length > 0) ? true: false;
}
const onChangeMensaje = (e) => {
  const { name, value } = e.target;
  setLogin({
    ...login,
    [name]: value,
  });
};

  const onSubmit = async(e) => {
    e.preventDefault();
    const resultlogin = await fetchstoken('login', login , 'POST');
    if(resultlogin.ok){
    resultlogin.usuarioBd.online = true;
     const {__v,...state} = resultlogin.usuarioBd;
     localStorage.setItem('token',resultlogin.token);
      conectarSocket();
     dispatch(loginstate(state));
    }else{
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      Toast.fire({
        icon: 'error',
        title: resultlogin.msg
      })
    }
      };

  return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: "22vh",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

          }}
        >
          <Avatar sx={{ m: 1, bgcolor: teal[900] }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Ingresar
          </Typography>
          <Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name='correo'
                  autoComplete="email"
                  onChange={onChangeMensaje}
                  value={login.correo}

                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name='password'
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={onChangeMensaje}
                  value={login.password}
                />
              </Grid>
             
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor:teal[900], ":hover":{bgcolor: teal[700] } }}
              disabled={!loginsesion()}
            >
              Ingresar
            </Button>
            
          </Box>
        </Box>
      </Container>
  );
}