import { types } from "../types/authLogin";

export const loginstate = (usuario) =>({
type: types.login,
payload: usuario
})

export const regenerate = () =>({
  type: types.regenerate
 })
 
 export const agregarInformacion = (datos) =>({
  type: types.agregarInformacion,
  payload:datos
 })

 export const actualizarfoto = (url) =>({
  type: types.UpdateFoto,
  payload: url
 })

 export const agregarfotobanner = (url) =>({
  type: types.agregarFotoBanner,
  payload: url
})

export const agregarfotoInformacion = (url) =>({
  type: types.agregarFotosInformacion,
  payload: url
})

export const agregarInformacionText = (modificar) =>({
  type: types.agregarInformacionText,
  payload: modificar
})

 export const subidafotos = (urlcompleta) =>({
  type: types.RecibirFotos,
  payload: urlcompleta
 })

 export const borrarfotos = (urleliminar) =>({
  type: types.RecibirFotos,
  payload: urleliminar
 })

 