import React, { useCallback, useContext, useEffect, useState } from "react";
import "./CrearProducto.scss";
import { UploadPhoto } from "../helpers/cloudinaryUpload";
import { SocketContext } from "../redux/context/contextchat";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { fetchCToken } from "../helpers/fetchmetod";
import { cargarproductos } from "../redux/actions/productos";
import Cajaproductosubidos from "./Cajaproductosubidos";
import CircularProgress from "./CircularProgress";
import { VscDiffAdded, VscError } from "react-icons/vsc";

export const CrearProducto = ({ history }) => {
  const [carga, setCarga] = useState(true);
  const dispatch = useDispatch();
  const miusuario = useSelector((yo) => yo.infoUsuario);
  const productos = useSelector((productos) => productos.productos.productos);
  const { socket } = useContext(SocketContext);
  const [NewProducto, setNewProducto] = useState({
    titulo: "",
    Categoria: "",
    Familia: "",
    subCategoria: "",
    Age: "2022",
    descripsion: "",
    Precio: 0,
    PrecioD: 0,
  });
  const [familias, setFamilias] = useState([]);
  const [subCategorias, setsubCategorias] = useState([]);
  const [Categorias, setCategorias] = useState([]);
  const [productoPrincipal, setProductPrincipal] = useState({});

  const obtenerproductos = useCallback(async () => {
    const ordenes = await fetchCToken("crearproducto");
    const categorias = await fetchCToken("categorias");
    const productoPrincipal = await fetchCToken("crearproductoprincipal");

    if (!ordenes.ok && !categorias.ok && !productoPrincipal.ok) {
      return;
    }
    setProductPrincipal(productoPrincipal.data)
    setCarga(false);
    setCategorias(categorias.categoria);
    setsubCategorias(categorias.subCategoria);
    setFamilias(categorias.familia);

    dispatch(cargarproductos(ordenes.producto));
  }, [dispatch]);
  useEffect(() => {
    obtenerproductos();
  }, [obtenerproductos]);
  const [urlmas, setUrl] = useState({
    secure_url:
      "https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg",
    public_id: 0,
  });
  const [agregar, setagregar] = useState(false);
  const onChangeMensaje = (e) => {
    const { name, value } = e.target;
    setNewProducto({
      ...NewProducto,
      [name]: value,
    });
  };

  const cambiar = () => {
    if (miusuario.rol === "Administrador" || miusuario.rol === "Empleado") {
      setagregar(!agregar);
      setNewProducto({
        titulo: "",
        Categoria: "",
        Familia: "",
        subCategoria: "",
        Age: "2022",
        descripsion: "",
        Precio: 0,
        PrecioD: 0,
      });
      setUrl({
        secure_url:
          "https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg",
        public_id: 0,
      });
    } else {
      return Swal.fire({
        position: "top-end",
        icon: "error",
        title: "No tienes Permiso",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const onFilesave = async (e) => {
    const file = e.target.files[0];
    setUrl(file);
  };
  const onFile = () => {
    document.querySelector("#fileproducto").click();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (NewProducto.titulo.length > 0 && NewProducto.descripsion.length > 0) {
      try {
        const url =
          urlmas.secure_url !==
          "https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg"
            ? await UploadPhoto(urlmas)
            : urlmas;
        socket.emit("producto", {
          url,
          uid: miusuario.uid,
          producto: NewProducto,
        });
        setUrl({
          secure_url:
            "https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg",
          public_id: 0,
        });
        setagregar(!agregar);
      } catch (err) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Sucedio un Error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Llenar Titulo y Descripsion",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return (
    <>
      {carga ? (
        <CircularProgress />
      ) : (
        <>
          <div className="header-page">
            <h1 className="header-page-title">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </h1>
            <p className="header-page-parrafo">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
          
          <div className="form-agregar-producto d-flex flex-column py-5" style={{overflowX:"auto"}}>
          <div className="square-form">
              <table summary="Mis Productos">
                <thead>
                  <tr>
                    <th scope="col">Imagen</th>
                    <th scope="col">Titulo</th>
                    <th scope="col">Detalles</th>
                    <th scope="col">Descripsion</th>
                    <th scope="col">Accion</th>
                  </tr>
                </thead>
                <tbody>
                {productoPrincipal?
                        <Cajaproductosubidos
                          Producto={productoPrincipal}
                          history={history}
                          rol={miusuario.rol}
                          categorias={Categorias}
                          subCategorias={subCategorias}
                          Familias={familias}
                          setProductPrincipal={setProductPrincipal}
                          notDeleteComponent
                        />
                        :null}
                </tbody>
              </table>
            </div>
          <button
            onClick={cambiar}
            style={{
              margin: "auto",
              marginBottom: "30px",
              color: "white",
              border: "none",
              padding: "9px 26px",
              backgroundColor: "#3D9A67",
              ":hover": { opacity: "0.5", background: "#3D9A67" },
            }}
          >
            {agregar ? (
              <VscError fontSize="17px" className="me-2" />
            ) : (
              <VscDiffAdded fontSize="17px" className="me-2" />
            )}{" "}
            {agregar ? "Cancelar" : "Agregar Producto"}
          </button>
            <div className="square-form">
              <table summary="Mis Productos">
                <thead>
                  <tr>
                    <th scope="col">Imagen</th>
                    <th scope="col">Titulo</th>
                    <th scope="col">Detalles</th>
                    <th scope="col">Descripsion</th>
                    <th scope="col">Accion</th>
                  </tr>
                </thead>
                <tbody>
                  {agregar === true ? (
                    <tr>
                      <td scope="row">
                        <img
                          className="hover"
                          src="https://res.cloudinary.com/dmgfep69f/image/upload/v1642034441/tu86rbwmkpjsyk3vcvr0.jpg"
                          onClick={onFile}
                          alt="productousers"
                        ></img>
                      </td>
                      <td>
                        <textarea
                          className="textproducto"
                          value={NewProducto.titulo}
                          name="titulo"
                          maxLength={100}
                          onChange={onChangeMensaje}
                        ></textarea>
                      </td>
                      <td>
                        <ul>
                          <li>
                            <b>familia: </b>
                            <select
                              name="Familia"
                              onChange={onChangeMensaje}
                              value={NewProducto.Familia}
                            >
                              <option></option>
                              {familias.map((categoria) => (
                                <option>{categoria}</option>
                              ))}
                            </select>
                          </li>
                          {NewProducto.Familia ? (
                            <li>
                              <b>Categoria: </b>
                              <select
                                name="Categoria"
                                onChange={onChangeMensaje}
                                value={NewProducto.Categoria}
                              >
                                <option></option>
                                {Categorias.map((categoria) => (
                                  <option>{categoria}</option>
                                ))}
                              </select>
                            </li>
                          ) : null}
                          {NewProducto.Categoria ? (
                            <li>
                              <b>subCategoria: </b>
                              <select
                                name="subCategoria"
                                onChange={onChangeMensaje}
                                value={NewProducto.subCategoria}
                              >
                                <option></option>
                                {subCategorias.map((categoria) => (
                                  <option>{categoria}</option>
                                ))}
                              </select>
                            </li>
                          ) : null}
                          <li>
                            <b>Modelo: </b>{" "}
                            <select
                              name="Age"
                              onChange={onChangeMensaje}
                              value={NewProducto.año}
                            >
                              <option>2011</option>
                              <option>2012</option>
                              <option>2013</option>
                              <option>2014</option>
                              <option>2015</option>
                              <option>2016</option>
                              <option>2017</option>
                              <option>2018</option>
                              <option>2019</option>
                              <option>2020</option>
                              <option>2021</option>
                              <option>2022</option>
                            </select>
                          </li>

                          <li>
                            <b>Precio: </b>{" "}
                            <input
                              type="number"
                              placeholder="Precio"
                              name="Precio"
                              onChange={onChangeMensaje}
                              value={NewProducto.Precio}
                            ></input>{" "}
                          </li>
                          <li>
                            <b>Precio con Descuento: </b>{" "}
                            <input
                              type="number"
                              placeholder="Precio Descuento"
                              name="PrecioD"
                              onChange={onChangeMensaje}
                              value={NewProducto.PrecioD}
                            ></input>{" "}
                          </li>
                        </ul>
                      </td>
                      <td>
                        <textarea
                          className="textproducto"
                          value={NewProducto.descripsion}
                          name="descripsion"
                          onChange={onChangeMensaje}
                        ></textarea>
                      </td>
                      <td>
                        <button
                          type="submit"
                          className="botonproductoagregar add "
                          onClick={onSubmit}
                        >
                          Publicar
                        </button>
                      </td>
                    </tr>
                  ) : null}
                  {productos.length > 0
                    ? productos.map((producto) => (
                        <Cajaproductosubidos
                          key={producto.pid + "5"}
                          Producto={producto}
                          history={history}
                          rol={miusuario.rol}
                          categorias={Categorias}
                          subCategorias={subCategorias}
                          Familias={familias}
                        />
                      ))
                    : null}
                </tbody>
              </table>
            </div>
            <input
              type="file"
              id="fileproducto"
              aria-label="File browser example"
              onChange={onFilesave}
            ></input>
          </div>
        </>
      )}
    </>
  );
};
