import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import { useHistory } from "react-router-dom";
import { useSelector} from 'react-redux';
import { teal } from '@mui/material/colors';
import LogoFerreagros from './images/logoFerreagros.png';
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function HeaderMUI() {
  const mediaQuery = useMediaQuery('(max-width:700px)');
  let history = useHistory();
  const [state, setState] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [busqueda, setbusqueda] = React.useState('');
  const miusuario =  useSelector(yo => yo.infoUsuario.rol);
  const carritoproducto = useSelector(carritos => carritos.productos.carrito);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);



  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const onChangeMensaje = (e) => {
    setbusqueda(e.target.value);
  };

  const onEnter= (e) => {
    if(e.key === 'Enter'){
      history.push(`/busqueda/${busqueda}`);
      setbusqueda("");
    }
  
  };

 
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };


  const menuId = 'primary-search-account-menu';


  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="carrito" color="inherit" onClick={()=> redirect("/carrito")}>
          <Badge badgeContent={carritoproducto.length} color="error">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
        <p>carrito</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={()=> redirect("/login")}
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const redirect = (direccion) => {
    history.push(direccion);
    }


  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,display:"flex",flexDirection:"column",alignItems:"center" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <img src={LogoFerreagros} style={{cursor:"pointer",width:"90%",marginTop:"29px",marginBottom:"6px"}} onClick={()=> redirect("/")}/>
      <List sx={{width:"100%"}}>
          <ListItem key={"categorias"} disablePadding onClick={()=> redirect("/categorias/todos")}>
            <ListItemButton>
              <ListItemIcon>
              <CategoryRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={"Categorias"} />
            </ListItemButton>
          </ListItem>
          {(miusuario === 'Administrador' || miusuario === 'Empleado' || miusuario === 'Observador')?<>
          <ListItem key={"Crear Categoria"} disablePadding onClick={()=> redirect("/crearCategorias/familia")}>
            <ListItemButton>
              <ListItemIcon>
              <InventoryRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={"Crear Categoria"} />
            </ListItemButton>
          </ListItem>
          <ListItem key={"Crear Producto"} disablePadding onClick={()=> redirect("/crearproducto")}>
            <ListItemButton>
              <ListItemIcon>
              <AddBusinessRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={"Crear Producto"} />
            </ListItemButton>
          </ListItem>
          </>:null}
      </List>
      {(miusuario === 'Administrador')?<>
      <Divider />
      <List sx={{width:"100%"}}>
      <ListItem key={"Usuarios"} disablePadding onClick={()=> redirect("/usuarios")}>
            <ListItemButton>
              <ListItemIcon>
              <PersonAddAltRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={"Usuarios"} />
            </ListItemButton>
          </ListItem>
      </List>
      </>:null}
      
      <Divider />
      <List sx={{width:"100%"}}>
          <ListItem key={"Fotos"} disablePadding onClick={()=> redirect("/imagenes")}>
            <ListItemButton>
              <ListItemIcon>
              <ImageRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={"Fotos"} />
            </ListItemButton>
          </ListItem>
          <ListItem key={"Videos"} disablePadding onClick={()=> redirect("/video")}>
            <ListItemButton>
              <ListItemIcon>
              <VideocamRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={"Videos"} />
            </ListItemButton>
          </ListItem>
          <ListItem key={"Sobre Nostros"} disablePadding onClick={()=> redirect("/perfil")}>
            <ListItemButton>
              <ListItemIcon>
              <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={"Sobre Nostros"} />
            </ListItemButton>
          </ListItem>
      </List>
    </Box>
  );

  return (<>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{backgroundColor: teal[900]}}>
        <Toolbar className='justify-content-between'>
          <Box className='d-flex flex-row align-items-center'>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
  
          {mediaQuery?null:<img src={LogoFerreagros} style={{cursor:"pointer",height:"50px"}} onClick={()=> redirect("/")}/>}
          </Box>
          <Search sx={{ flexGrow: 1,maxWidth:"600px" }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Buscar"
              inputProps={{ 'aria-label': 'search' }}
              onChange={onChangeMensaje}
              onKeyPress={onEnter}
              value={busqueda}
            />
          </Search>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={()=> redirect("/carrito")}>
              <Badge badgeContent={carritoproducto.length} color="error">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={()=> redirect("/login")}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </Box>
     <Drawer
     anchor={'left'}
     open={state}
     onClose={toggleDrawer(false)}
   >
     {list('left')}
   </Drawer>
   </>
  );
}