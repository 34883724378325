import "./FilterCard.scss";
import React, { useState, useEffect,useCallback } from "react";
import { fetchstoken } from "../helpers/fetchmetod";

function FilterCard({history,opciones}) {
  const [Width, setWidth] = useState(window.innerWidth);
  const [alertfilter, setAlertFilter] = useState(false);
  const [ordenarfilter, setOrdenarFilter] = useState(false);
  const [Categorias, setCategorias] = useState([]);

  const obtenerproductos = useCallback(
    async() => {
      const categorias = await fetchstoken('categorias');
      
      if( !categorias.ok){
      return ;
      }
      setCategorias(categorias.categoria);
    },[]
  )

  useEffect( ()=>{
    obtenerproductos()
   },[obtenerproductos])

  const cambiarTamaño = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", cambiarTamaño);
    return () => {
      window.removeEventListener("resize", cambiarTamaño);
    };
  });
const filter = () =>{
  const tr = alertfilter?false:true;
  setOrdenarFilter(false);
  setAlertFilter(tr)
}

const ordenar = () =>{
  const tr = ordenarfilter?false:true;
  setAlertFilter(false);
  setOrdenarFilter(tr);
}
const [filtered, setFilter] = useState({
  nuevo:false,
  usado:false,
  modelo:'',
  ubicacion: '',
  Garantia:false,
  min:0,
  max: 10000000000,
  categoria:'',
  enviogratis:false,
  mayor:false,
  menor:false
});

const onChangeMensaje = (e) => {
  const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
  setFilter({
    ...filtered,
    [name]: value,
  });
};



 const redirect = () => {
  history.push(`/busqueda/${opciones}?${(filtered.modelo.length  !== 0 )?'modelo='+filtered.modelo:''}${(filtered.ubicacion.length !== 0 )?'&ubicacion='+filtered.ubicacion:''}${(filtered.min !==0 )?'&min='+filtered.min:''}${(filtered.max!==10000000000 )?'&max='+filtered.max:''}${(filtered.categoria.length !==0 )?'&categoria='+filtered.categoria:''}${(filtered.enviogratis!== false )?'&enviogratis='+filtered.enviogratis:''}${(filtered.nuevo!== false )?'&nuevo='+filtered.nuevo:''}${(filtered.usado!== false)?'&usado='+filtered.usado:''}${(filtered.mayor!==false )?'&mayor='+filtered.mayor:''}${(filtered.menor!==false )?'&menor='+filtered.menor:''}`);
}; 
const limpiar = () =>{
  setFilter({
    nuevo:false,
    usado:false,
    modelo:'',
    ubicacion: '',
    Garantia:false,
    min:0,
    max: 10000000000,
    categoria:'',
    enviogratis:false,
    mayor:false,
    menor:false
  });
  history.push(`/busqueda/${opciones}?`);

}
  if (Width > 650) {
    return (
      <div className="divfilterflex">
        <form className="formfilter">
          <h3 className="tamaño colorf">Busqueda</h3>
          <div className="flexche mb-3">
            <h4 className="hdeproduc colorf m-0 me-2">Precio</h4>
            <input className="inputf me-2" placeholder="minimo"  name="min" value={filtered.min} onChange={onChangeMensaje}/>
            <input className="inputf" placeholder="maximo" name="max" value={filtered.max} onChange={onChangeMensaje}/>
          </div>
          <div className="flexche mb-3">
            <h4 className="hdeproduc colorf">Categoria</h4>
            <select name="categoria" className="select"   value={filtered.categoria} onChange={onChangeMensaje}>
            {Categorias?.map((categoria) =>(
               <option>{categoria}</option>
              ))
        }

            </select>
          </div>

          <h4 className="hdeproduc colorf mb-3">Ordenar</h4>
          <div className="flexche mb-3">
            <div className="flexche">
              <label className="me-2 hdeproduc" style={{fontFamily:"Poppins"}}>Mayor
              </label>
              <input type="checkbox" className="checkbox" name='mayor' value={filtered.mayor} onChange={onChangeMensaje}/>
            </div>
            <div className="flexche">
              <label className="me-2 hdeproduc" style={{fontFamily:"Poppins"}}>Menor
              </label>
              <input type="checkbox" className="checkbox" name='menor'  value={filtered.menor} onChange={onChangeMensaje}/>
            </div>
          </div>
          <div className="d-flex flex-row">
          <button className='botonbusquedafilter me-3' type='button' onClick={redirect}>buscar</button>
          <button className='botonbusquedafilter' type='button' onClick={limpiar}>Limpiar</button>
          </div>

        </form>
        <hr></hr>
      </div>
    );
  } else {
    return (
      <>
        <div className="filtercelular">
          <div className="flexbotonfil" onClick={()=>filter()}>
            <i className="bx bxs-filter-alt iconfilter"></i>
            <p>Filtrar</p>
          </div>
          <hr></hr>
          <div className="flexbotonfil" onClick={()=>ordenar()}>
            <i className="bx bx-sort iconfilter"></i>
            <p>Ordenar</p>
          </div>
        </div>

        {(alertfilter === true)?
        
         <form className="formfilter">
         
         <h3 className="tamaño colorf">Filtro</h3>
     
         <div className="flexche">
           <h4 className="hdeproduc colorf">Modelo</h4>
           <select  className="select" name='modelo'  value={filtered.modelo} onChange={onChangeMensaje}>
            <option value=""></option>
              <option value="2011">2011</option>
              <option value="2012">2012</option>
              <option value="2013">2013</option>
              <option value="2014">2014</option>
              <option value="2015">2015</option>
              <option value="2016">2016</option>
              <option value="2017">2017</option>
              <option value="2018">2018</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
            </select>
         </div>
    
     
         <div className="flexche">
         <h4 className=" ta-center">Precio</h4>
           <input className="inputf" placeholder="minimo" name="min" value={filtered.min} onChange={onChangeMensaje}/>
           - 
           <input className="inputf" placeholder="maximo" name="max" value={filtered.max} onChange={onChangeMensaje}/>
         </div>
         <div className="flexche">
           <h4 className=" ta-center" >Categoria</h4>
           <select name="categoria" className="select"   value={filtered.categoria} onChange={onChangeMensaje}>
            <option value=""></option>
              <option value="Electrodomesticos">Electrodomesticos</option>
              <option value="Mascotas">Mascotas</option>
              <option value="Repuestos">Repuestos</option>
              <option value="Implementos">Implementos</option>
              <option value="Maquillaje">Maquillaje</option>
            </select>
         </div>
    
         <button className='botonbusquedafilter' type='button' onClick={redirect}>buscar</button>
          <button className='botonbusquedafilter' type='button' onClick={limpiar}>Limpiar</button>
       </form>
        : null}



{(ordenarfilter === true)?
        
        <form className="formfilter">
        
        <h3 className="tamaño colorf">Ordenar</h3>
        <div className="flexche">
        <h4 className="hdeproduc colorf">Precio</h4>
          <div className="flexcher">
            <p >Mayor+</p>
            <input type="checkbox" className="checkbox" name='mayor' value={filtered.mayor} onChange={onChangeMensaje}/>
          </div>
          <div className="flexcher">
            <p >Menor -</p>
            <input type="checkbox" className="checkbox" name='menor' value={filtered.menor} onChange={onChangeMensaje}/>
          </div>
        </div>
    
      
        
        <button className='botonbusquedafilter' type='button' onClick={redirect}>buscar</button>
          <button className='botonbusquedafilter' type='button' onClick={limpiar}>Limpiar</button>
      </form>
       : null}

        
      </>
    );
  }
}

export default FilterCard;
